import { useRef } from 'react';
import * as T from './types';
import * as S from './styles';

function Carousel(props: T.CarouselProps) {
  const { items, noCarousel } = props;

  const carousel = useRef<HTMLDivElement>(null);
  const card = useRef<HTMLDivElement>(null);

  return (
    <>
      <S.Container ref={carousel} noCarousel={noCarousel}>
        {items.map((item, index) => (
          <S.Card ref={card} key={index}>
            {item}
          </S.Card>
        ))}
      </S.Container>
    </>
  );
}
export default Carousel;
