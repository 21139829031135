import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin: auto;
  background: ${(props) => props.theme.colors.bg.light};
`;

export const Footer = styled.footer`
  min-height: 80px;
  width: 100%;
  max-width: 880px;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1.5rem;
  padding: 1rem 2rem;
  align-items: center;

  span {
    font-weight: bold;
  }
`;

export const LogoWrap = styled.div``;

export const CopyRights = styled.div``;
