import Box from 'components/commom/Box';
import Button from 'components/commom/Button';
import StyledButton from 'components/StyledButton/styledButton';
import Typography from 'components/commom/Typography';
import ReactPlayer from 'react-player';
import * as S from './styles';
import VideoCarousel from '../Video Carousel/VideoCarousel';


function SectionSimple() {
  const videos = [
    {
      id: 1,
      title: 'Video 1',
      videoUrl: 'https://player.vimeo.com/video/916794395?',
    },
    {
      id: 2,
      title: 'Video 2',
      videoUrl: 'https://player.vimeo.com/video/916809936?',
    },
    {
      id: 2,
      title: 'Video 3',
      videoUrl: 'https://player.vimeo.com/video/680191118?',
    }
  ];
  return (
    <Box bg="primary">
      <Box boxMain>
        <Box flexDirection="column" gap={50} width='100%'>
          <Box
            flexDirectionMobile="column"
            justifyContent="flex-start"
            alignItems="center"
            alignItemsMob="flex-start"
            gap={16}

          >
            <Box flexDirection="column" gap={12}>
              <S.TextWrap>
                <Typography variant="title" color="primary">
                  Plataforma S
                </Typography>
                <Typography variant="body" color="light" noWrap>
                  A nossa plataforma é formada pelo conjunto de tecnologias que
                  promovem uma excelente experiência entre aluno e IES.
                </Typography>z
              </S.TextWrap>
            </Box>
            <Button neonEffect={true}
              onClick={() => {
                window.open(
                  'https://calendly.com/hikaro-q3q/30min?back=1',
                  '_blank'
                );
              }}
              text="Saiba mais"
              variant="primary"


            />

          </Box>
          <Box flex={false}>
            <S.Container>
              <S.VideoWrap>
                <VideoCarousel videos={videos} />
              </S.VideoWrap>
            </S.Container>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
export default SectionSimple;
