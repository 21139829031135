import React, { ReactElement, MouseEventHandler } from 'react';
import { ReactSVG } from 'react-svg';
import * as S from './styles'; 

// Definição de tipos
export type ButtonVariants =
  | 'primary'
  | 'outline'
  | 'active'
  | 'secondary'
  | 'ghost'
  | 'ghostSecondary';

export type ButtonSizes = 'small' | 'medium' | 'large';

export type ButtonProps = {
  text: string;
  full?: boolean;
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  type?: 'button' | 'submit' | 'reset';
  variant?: ButtonVariants;
  size?: ButtonSizes;
  icon?: string;
  iconPosition?: 'left' | 'right';
  hiddenTextOnMobile?: boolean;
  ariaLabel?: string;
  loading?: boolean;
  breakSpaces?: boolean;
  neonEffect?: boolean;
};

// Componente Button
const Button = (props: ButtonProps): ReactElement => {
  const {
    text,
    full = false,
    disabled,
    onClick,
    type,
    variant = 'primary',
    size = 'medium',
    icon,
    iconPosition = 'left',
    hiddenTextOnMobile = false,
    ariaLabel,
    loading,
    breakSpaces = false,
    neonEffect = false
  } = props;

  const styles = {
    full,
    disabled,
    variant,
    size,
    iconPosition,
    loading,
    breakSpaces,
    neonEffect
  };

  return (
    <S.Container {...styles}>
      <S.Button
        data-testid={`${text}-button`}
        type={type}
        onClick={onClick}
        disabled={disabled || loading}
        aria-label={ariaLabel}
        neonEffect={props.neonEffect || false}
        
      >
        {icon && !loading && (
          <S.Icon
            hiddenTextOnMobile={hiddenTextOnMobile}
            variant={variant}
            className={hiddenTextOnMobile ? 'no-padding' : ''}
          >
            <ReactSVG src={icon} />
          </S.Icon>
        )}

        {/* Descomente a linha abaixo para adicionar um indicador de carregamento */}
        {/* {loading && <Spinner size="mini" />} */}

        <S.Text
          breakSpaces={breakSpaces}
          className={hiddenTextOnMobile ? 'h-mobile' : ''}
        >
          {text}
        </S.Text>
      </S.Button>
    </S.Container>
  );
};

export default Button;
