import styled from 'styled-components';

export const Card = styled.div`
  width: 100%;
  max-width: 415px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.75rem;

  & img {
    width: 100%;
    max-width: 415px;
  }

  @media (max-width: 1024px) {
    margin-bottom: 50px;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.75rem;
`;

export const CardTitle = styled.div``;

export const IconWrap = styled.div`
  width: 100%;
  max-width: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;
