import styled from 'styled-components';

export const BooksWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
`;
export const FlexBooks = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 3.125rem;
`;
