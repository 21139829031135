import React, { MutableRefObject } from 'react';
import * as S from './styles';
import * as T from './types';

const Typography: React.ForwardRefRenderFunction<
  unknown,
  T.BaseTypographyProps
> = (props, ref) => {
  const {
    variant = 'h1',
    children,
    align = 'left',
    color = 'dark',
    weight,
    noWrap = false,
    fontSize,
    lineHeight,
    fill,
    ellipsis,
    font,
    alignDesktop,
    uppercase,
    ...rest
  } = props;

  const styles = {
    variant,
    align,
    color,
    weight,
    noWrap,
    fontSize,
    lineHeight,
    fill,
    ellipsis,
    font,
    alignDesktop,
    uppercase,
  };

  return (
    <S.Container>
      <S.Heading
        ref={ref as MutableRefObject<HTMLParagraphElement>}
        {...styles}
        {...rest}
      >
        {children}
      </S.Heading>
    </S.Container>
  );
};

export default React.forwardRef<unknown, T.BaseTypographyProps>(Typography);
