function variantSizeMobile(variant: string): string {
  switch (variant) {
    case 'h1':
      return '40px';
    case 'h2':
      return '32px';
    case 'h3':
      return '24px';
    case 'h4':
      return '20px';
    case 'hero':
      return '42px';
    case 'subtitle':
      return '20px';

    default:
      return '14px';
  }
}

function variantSize(variant: string): string {
  switch (variant) {
    case 'h1':
      return '36px';
    case 'h2':
      return '32px';
    case 'h3':
      return '28px';
    case 'h4':
      return '24px';
    case 'hero':
      return '42px';
    case 'subtitle':
      return '20px';

    default:
      return '14px';
  }
}

export { variantSize, variantSizeMobile };
