import styled from 'styled-components';

export const Background = styled.div`
  background: ${(props) => props.theme.colors.bg.dark};
  /* background: #000; */
  min-height: 500px;
`;

export const VideoBox = styled.div`
  max-width: 500px;
  width: 80%;
  margin: auto;
  margin-bottom: 3.125rem;
  background-color: #c39879;
  > div {
    /* opacity: 0; */
  }

  @media (min-width: 1024px) {
    position: relative;
    bottom: 5rem;
    max-width: 750px;
    width: 100%;
    max-height: 370px;
    display: flex;
    justify-content: center;
  }
`;
