import styled from 'styled-components';

export const Item = styled.div`
  background: ${(theme) => theme.theme.colors.bg.secondary};
  width: 100%;
  padding: 0.75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  max-width: 800px;
  margin: auto;
  border-radius: 30px;
  border: solid 1px #fff;
`;

export const TextWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.563rem;
  max-width: 530px;
`;

export const ImageWrap = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: gray;

  img {
    width: 100%;
  }
`;
export const Wrap = styled.div`
  display: flex;
  gap: 0.75rem;
`;
