import Box from 'components/commom/Box';
import Typography from 'components/commom/Typography';
import * as S from './styles';

function Policy() {
  return (
    <Box bg="primary">
      <Box boxMain>
        <Box flexDirection="column" gap={50}>
          <Box flexDirection="column" gap={12}>
            <Typography noWrap variant="title" color="primary">
              Política de Privacidade de Dados
            </Typography>
            <Typography noWrap variant="body" color="light" weight="bold">
              OBJETIVO
            </Typography>
            <Typography noWrap variant="body" color="light">
              A CONTED. TECH SISTEMAS E DESENVOLVIMENTO LTDA, pessoa jurídica de
              direito privado, inscrita no CNPJ sob o nº. 45.926.966/0001- 92,
              em respeito aos nossos usuários, assume o comprometimento em zelar
              pela proteção e tratamento adequado dos dados pessoais, nos termos
              da Lei Geral de Proteção de Dados Pessoais (LGPD), Lei nº
              13.709/18, e demais leis pertinentes, trazendo como princípios a
              transparência e construção de relações sólidas, baseadas na
              confiança e no benefício mútuo.
            </Typography>
            <Typography noWrap variant="body" color="light">
              Esta política de privacidade contém informações sobre como
              tratamos, total ou parcialmente, de forma automatizada ou não, os
              dados pessoais dos usuários que utilizam nossos serviços.
            </Typography>
            <Typography noWrap variant="body" color="light">
              Além disso, esta política tem como intuito informar os direitos do
              usuário e esclarecer quando e como poderá exercê-los, procurando:
            </Typography>
            <S.List>
              <li>
                Estar em conformidade com as leis e regulamentações aplicáveis
                seguindo as melhores práticas;
              </li>
              <li>
                Proteger os direitos de todos contra os riscos de violações de
                dados pessoais;
              </li>
              <li>
                Transparência quanto aos procedimentos da empresa no tratamento
                de dados pessoais;
              </li>
              <li>
                Promoção da conscientização quanto à proteção de dados pessoais
                e questões de privacidade.
              </li>
            </S.List>
            <Typography noWrap variant="body" color="light" weight="bold">
              A QUEM SE DESTINA
            </Typography>
            <Typography noWrap variant="body" color="light">
              Esta política se destina a VOCÊ, titular de dados pessoais que são
              passíveis de tratamento pela nossa organização, representando um
              compromisso assumido pela empresa na forma de tratamento de seus
              dados pessoais. As declarações aqui apresentadas estão pautadas no
              princípio da honestidade e respeito à sua privacidade pela prática
              do tratamento com preservação de seus direitos e garantias
              individuais que alcancem o benefício mútuo.
            </Typography>
            <Typography noWrap variant="body" color="light">
              A responsabilidade pelo correto tratamento dos dados pessoais
              dentro da empresa é compartilhada entre todos aqueles que atuam
              como operadores, sendo fundamental a cooperação de todos para que
              a empresa esteja sempre em conformidade com a lei, oferecendo
              segurança a todos os titulares de dados pessoais sob seu controle.
            </Typography>
            <Typography noWrap variant="body" color="light" weight="bold">
              REFERÊNCIAS
            </Typography>
            <Typography noWrap variant="body" color="light">
              Controlador: pessoa natural ou jurídica, de direito público ou
              privado, a quem competem as decisões referentes ao tratamento de
              dados pessoais
            </Typography>
            <Typography noWrap variant="body" color="light">
              Criptografia: mecanismo de segurança e privacidade que torna a
              informação ininteligível para quem não possui a “chave” de acesso,
              visando a proteção de dados e informações sigilosas;
            </Typography>
            <Typography noWrap variant="body" color="light">
              Dados pessoais: são informações relativas a uma pessoa singular
              identificada ou identificável; é considerada identificável uma
              pessoa singular que possa ser identificada, direta ou
              indiretamente, em especial por referência a um identificador, como
              por exemplo um nome, um número de identificação, dados de
              localização, identificadores por via eletrônica ou a um ou mais
              elementos específicos da identidade física, fisiológica, genética,
              mental, económica, cultural ou social dessa pessoa singular;
            </Typography>
            <Typography noWrap variant="body" color="light">
              Dado pessoal sensível: dado pessoal sobre origem racial ou étnica,
              convicção religiosa, opinião política, filiação a sindicato ou a
              organização de caráter religioso, filosófico ou político, dado
              referente à saúde ou à vida sexual, dado genético ou biométrico,
              quando vinculado a uma pessoa natural;
            </Typography>
            <Typography noWrap variant="body" color="light">
              ISO 27001: Padrão internacional de boas práticas em segurança da
              informação;
            </Typography>
            <Typography noWrap variant="body" color="light">
              LGPD: Lei Geral de Proteção de Dados Pessoais, Lei nº 13.709, de
              14 de agosto de 2018;
            </Typography>
            <Typography noWrap variant="body" color="light">
              Operador: Pessoa natural ou jurídica, de direito público ou
              privado, que realiza o tratamento de dados pessoais em nome do
              Controlador;
            </Typography>
            <Typography noWrap variant="body" color="light">
              Software: Conjunto de componentes lógicos de um computador ou
              sistema de processamento de dados; programa, rotina ou conjunto de
              instruções que controlam o funcionamento de um computador; suporte
              lógico.
            </Typography>
            <Typography noWrap variant="body" color="light">
              Termo de Uso: Documento que descreve as condições de uso e
              política de privacidade no que se refere ao software;
            </Typography>
            <Typography noWrap variant="body" color="light">
              Tratamento: toda operação realizada com dados pessoais, como as
              que se referem a coleta, produção, recepção, classificação,
              utilização, acesso, reprodução, transmissão, distribuição,
              processamento, arquivamento, armazenamento, eliminação, avaliação
              ou controle da informação, modificação, comunicação,
              transferência, difusão ou extração;
            </Typography>
            <Typography noWrap variant="body" color="light" weight="bold">
              QUEM SOMOS
            </Typography>
            <Typography noWrap variant="body" color="light">
              Somos uma startup de tecnologia localizada em quatro estados do
              Brasil que desenvolve soluções educacionais para todo o país.
              Nossos principais produtos são a plataforma LMS Simple, a
              Produtora registrada na Agência Nacional do Cinema (ANCINE) e a
              Revista Científica Evolucione. Todas as nossas soluções foram
              pensadas para facilitar o desenvolvimento do EaD em instituições
              de ensino e universidades corporativas.
            </Typography>
            <Typography noWrap variant="body" color="light" weight="bold">
              TRATAMENTO DE DADOS
            </Typography>
            <Typography noWrap variant="body" color="light">
              Quais dados?
            </Typography>
            <Typography noWrap variant="body" color="light">
              Para atender a LGPD, a Conted Tech realizará o tratamento de seus
              dados pessoais, tais como: Nome, E-mail, Data de Nascimento,
              Número de Telefone, Endereço Completo.
            </Typography>
            <Typography noWrap variant="body" color="light">
              Já os dados considerados sensíveis, não é permitido seu tráfego e
              armazenamento em formato de imagem, devendo conduzi-los de forma
              criptografados.
            </Typography>
            <Typography noWrap variant="body" color="light">
              Entretanto, a Conted Tech não poderá ser responsabilizada pela
              (im)precisão, pela veracidade ou falta desta, quanto as
              informações e dados que o usuário prestar, bem como quando não
              houver a devida atualização dos dados respondendo pelo danos
              quando comprovada a sua responsabilidade em prestá-las com
              exatidão ou atualizá-las.
            </Typography>
            <Typography noWrap variant="body" color="light">
              Objetivo da coleta
            </Typography>
            <Typography noWrap variant="body" color="light">
              A Conted Tech compromete-se ao uso do dado tão somente para o
              objetivo e finalidade deste, caracterizando violação de
              privacidade caso não o ocorra.
            </Typography>
            <Typography noWrap variant="body" color="light">
              Uso justo dos dados pessoais
            </Typography>
            <Typography noWrap variant="body" color="light">
              A Conted Tech não coleta e realiza o tratamento de dados pessoais
              sem que exista uma necessidade; finalidade específica ou
              embasamento legal e compatível com o propósito de gestão de
              controle de acesso e segurança.
            </Typography>
            <Typography noWrap variant="body" color="light">
              Os dados pessoais serão acessados somente por profissionais
              devidamente autorizados, respeitando os princípios de
              proporcionalidade, necessidade e relevância para os objetivos dos
              negócios da Conted Tech, além do compromisso de confidencialidade
              e preservação da privacidade nos termos desta Política de
              Privacidade.
            </Typography>
            <Typography noWrap variant="body" color="light">
              Finalidade de uso dos dados pessoais
            </Typography>
            <Typography noWrap variant="body" color="light">
              Para melhor entendimento destacamos que o tratamento dos dados
              pessoais tem como finalidade exclusiva prover eficiência
              energética, conforto e segurança para os empreendimentos
              gerenciados pela Conted Tech.
            </Typography>
            <Typography noWrap variant="body" color="light">
              Como coletamos seus dados pessoais
            </Typography>
            <Typography noWrap variant="body" color="light">
              A captação e digitação de dados pessoais será realizada pelo
              Controlador, e/ou a mando do Controlador, e nos termos da política
              de cookies, sendo tratada pela Conted Tech através de Sistema de
              Informação.
            </Typography>
            <Typography noWrap variant="body" color="light">
              Compartilhamento
            </Typography>
            <Typography noWrap variant="body" color="light">
              As informações coletadas poderão ser compartilhadas com: (i)
              empresas do Grupo Conted Tech; (ii) demais empresas parceiras,
              quando forem necessárias para a adequada prestação dos serviços
              objeto de suas atividades; (iii) para proteção dos interesses da
              Conted Tech em qualquer tipo de conflito; (iv) mediante decisão
              judicial ou requisição de autoridade competente; (v) com
              autoridades judiciais, administrativas ou governamentais
              competentes, sempre que houver determinação legal, requerimento,
              requisição ou ordem judicial; (vi) de forma automática em caso de
              movimentações societárias, como fusão, aquisição e incorporação.
            </Typography>
            <Typography noWrap variant="body" color="light">
              Esclarecemos que suas informações poderão ser compartilhadas com
              empresas provedoras de infraestrutura tecnológica e operacional
              necessária para as atividades da Conted Tech, como intermediadoras
              de pagamento e provedores de serviço de armazenamento de
              informações.
            </Typography>
            <Typography noWrap variant="body" color="light">
              As informações coletadas poderão, ainda, ser utilizadas para fins
              publicitários, divulgação de eventos.
            </Typography>
            <Typography noWrap variant="body" color="light">
              Do cumprimento das obrigações legais
            </Typography>
            <Typography noWrap variant="body" color="light">
              A Conted Tech poderá compartilhar os dados coletados sempre e
              desde que que for originada de obrigação legal, programas
              governamentais e/ou cooperação com a autoridade nacional de
              proteção de dados, sempre com embasamento legal que certifique a
              obrigatoriedade.
            </Typography>
            <Typography noWrap variant="body" color="light">
              Direitos em relação aos seus dados pessoais
            </Typography>
            <Typography noWrap variant="body" color="light">
              O Controlador é o único responsável para realizar todo e qualquer
              atendimento de suas solicitações. Como titular de dados pessoais,
              você possui os seguintes direitos:
            </Typography>
            <Typography noWrap variant="body" color="light">
              I - confirmação da existência de tratamento;
            </Typography>
            <Typography noWrap variant="body" color="light">
              II - acesso aos dados;
            </Typography>
            <Typography noWrap variant="body" color="light">
              III - correção de dados incompletos, inexatos ou desatualizados;
            </Typography>
            <Typography noWrap variant="body" color="light">
              IV - anonimização, bloqueio ou eliminação de dados desnecessários,
              excessivos ou tratados em desconformidade;
            </Typography>
            <Typography noWrap variant="body" color="light">
              V - portabilidade dos dados, mediante requisição expressa;
            </Typography>
            <Typography noWrap variant="body" color="light">
              VI - eliminação dos dados pessoais tratados com o consentimento do
              titular;
            </Typography>
            <Typography noWrap variant="body" color="light">
              VII - informação das entidades públicas e privadas com as quais o
              controlador realizou uso compartilhado de dados;
            </Typography>
            <Typography noWrap variant="body" color="light">
              VIII - informação sobre a possibilidade de não fornecer
              consentimento e sobre as consequências da negativa;
            </Typography>
            <Typography noWrap variant="body" color="light">
              IX - revogação do consentimento,
            </Typography>
            <Typography noWrap variant="body" color="light">
              As solicitações acimas descritas e direcionadas ao Controlador são
              de exclusiva responsabilidade deste, decorrentes de suas
              exclusivas análises de legalidade e legitimidade para tratamento
              de Seus dados pessoais, bem como quanto a possibilidade de
              atendimento da solicitação apresentada.
            </Typography>
            <Typography noWrap variant="body" color="light">
              Lembramos apenas que a retirada do consentimento não afeta a
              legalidade do tratamento baseado no consentimento antes da
              respectiva retirada.
            </Typography>
            <Typography noWrap variant="body" color="light" weight="bold">
              SEGURANÇA
            </Typography>
            <Typography noWrap variant="body" color="light">
              A Conted Tech empenha esforços em manter seus dados pessoais
              protegidos e toma todas precauções e salvaguardas adequadas para
              fazê-lo, considerando:
            </Typography>
            <Typography noWrap variant="body" color="light">
              Segurança do sistema:
            </Typography>
            <Typography noWrap variant="body" color="light">
              A Conted Tech empenha os melhores esforços para proteção da
              informação, principalmente seus Dados Pessoais, aplicando
              procedimentos rigorosos e recursos de segurança necessários
              disponíveis à época, visando a impedir a violação de seus dados
              pessoais.
            </Typography>
            <Typography noWrap variant="body" color="light">
              Segurança do Banco de Dados:
            </Typography>
            <Typography noWrap variant="body" color="light">
              Os sistemas dispõem de processos de segurança lógicos, técnicos e
              administrativos compatíveis com a sensibilidade das informações
              coletadas, contudo, a segurança física é de responsabilidade
              exclusiva Controlador.
            </Typography>
            <Typography noWrap variant="body" color="light">
              Criptografia:
            </Typography>
            <Typography noWrap variant="body" color="light">
              A Conted Tech utiliza criptografia para transitar dados também com
              medidas de segurança para evitar acesso não autorizado aos
              servidores e serviços.
            </Typography>
            <Typography noWrap variant="body" color="light">
              Boas práticas:
            </Typography>
            <Typography noWrap variant="body" color="light">
              A Conted Tech utiliza criptografia para transitar dados também com
              medidas de segurança para evitar acesso não autorizado aos
              servidores e serviços.
            </Typography>
            <Typography noWrap variant="body" color="light">
              Boas práticas:
            </Typography>
            <Typography noWrap variant="body" color="light">
              A Conted Tech preza em atender aos requisitos de segurança e
              transparência, aos padrões de boas práticas e de governança e aos
              princípios gerais estabelecidos na LGPD.
            </Typography>
            <Typography noWrap variant="body" color="light">
              Vulnerabilidades:
            </Typography>
            <Typography noWrap variant="body" color="light">
              Embora a Conted Tech utilize medidas de segurança para proteger
              seus Dados Pessoais contra divulgação não autorizada, mau uso ou
              alteração, não há garantias de que as informações não poderão ser
              acessadas, divulgadas, alteradas ou destruídas por violação de
              qualquer uma das proteções físicas, técnicas ou administrativas,
              sendo que nessa situação a Conted Tech promoverá as medidas
              necessárias para mitigação dos danos.
            </Typography>
            <Typography noWrap variant="body" color="light">
              Retenção e Armazenamento
            </Typography>
            <Typography noWrap variant="body" color="light">
              Para fins de auditoria, segurança, controle de fraudes e
              preservação de direitos, poderão permanecer com o histórico de
              registro dos dados do usuário por prazo maior nas hipóteses que a
              lei ou norma regulatória assim estabelecer ou para preservação de
              direitos.
            </Typography>
            <Typography noWrap variant="body" color="light">
              BASE LEGAL
            </Typography>
            <Typography noWrap variant="body" color="light">
              Sem prejuízo do Controlador possuir sua própria análise e
              fundamentação legal para tratamento de Seus dados pessoais,
              destacamos:
            </Typography>
            <Typography noWrap variant="body" color="light">
              Obrigações Contratuais:
            </Typography>
            <Typography noWrap variant="body" color="light">
              Verificar a identidade do usuário;
            </Typography>
            <Typography noWrap variant="body" color="light">
              Arrecadar pagamentos e realizar cobranças;
            </Typography>
            <Typography noWrap variant="body" color="light">
              Enviar comunicações necessárias ao usuário, pertinentes ao
              contrato;
            </Typography>
            <Typography noWrap variant="body" color="light">
              Prestar suporte ou atendimento ao cliente.
            </Typography>
            <Typography noWrap variant="body" color="light">
              Interesses Legítimos do Controlador:
            </Typography>
            <Typography noWrap variant="body" color="light">
              Controle dos bloqueios de acesso gerenciados pelo sistema;
            </Typography>
            <Typography noWrap variant="body" color="light">
              Capitação de imagens de segurança em locais gerenciados pelo
              sistema;
            </Typography>
            <Typography noWrap variant="body" color="light">
              Detectar, prevenir ou, de outra forma, resolver fraudes, problemas
              técnicos ou de segurança, bem como para proteger-se contra danos
              aos direitos, à propriedade ou à segurança do Controlador;
            </Typography>
            <Typography noWrap variant="body" color="light">
              Gerenciar de informações inerentes à eficiência energética,
              conforto e segurança dos empreendimentos que utilizam os sistemas
              da Conted Tech;
            </Typography>
            <Typography noWrap variant="body" color="light" weight="bold">
              COOKIES
            </Typography>
            <Typography noWrap variant="body" color="light">
              A Conted Tech utiliza Cookies para facilitar o uso das páginas
              eletrônicas aos seus interesses e necessidades, bem como para
              compilarmos informações sobre a utilização de nossos sites e
              serviços, auxiliando a melhorar suas estruturas e seus conteúdos.
            </Typography>
            <Typography noWrap variant="body" color="light">
              Cookies são pequenos arquivos de texto armazenados pelo navegador
              quando você usa sites. Você pode controlar como os sites utilizam
              os cookies definindo as configurações de privacidade de seu
              navegador (consulte a função de ajuda do navegador para saber mais
              sobre o controle de cookies).
            </Typography>
            <Typography noWrap variant="body" color="light">
              Quando você visita nosso site ou acesso um sistema através do seu
              navegador, é inserido um ‘cookie’ no seu navegador por meio do
              software Google Analytics, onde são coletadas, anonimamente,
              informações, como endereço IP e localização geográfica.
            </Typography>
            <Typography noWrap variant="body" color="light">
              A qualquer momento, o Usuário poderá revogar seu consentimento
              quanto aos cookies, pelo que deverá apagar os cookies das Páginas
              utilizando as configurações de seu navegador de preferência.
            </Typography>
            <Typography noWrap variant="body" color="light" weight="bold">
              DA VIGÊNCIA E ATUALIZAÇÃO
            </Typography>
            <Typography noWrap variant="body" color="light">
              Vigência
            </Typography>
            <Typography noWrap variant="body" color="light">
              A política passa a viger a partir da data da assinatura da mesma
              pelo conselho de administração.
            </Typography>
            <Typography noWrap variant="body" color="light">
              Atualização
            </Typography>
            <Typography noWrap variant="body" color="light">
              Podemos atualizar esta política tanto quanto mudem os requisitos
              do negócio ou da lei vigente. Toda alteração deve ser publicada em
              nosso site, comunicando alteração e início de vigência.
            </Typography>
            <Typography noWrap variant="body" color="light" weight="bold">
              DISPOSIÇÕES
            </Typography>
            <Typography noWrap variant="body" color="light">
              Da nulidade
            </Typography>
            <Typography noWrap variant="body" color="light">
              Qualquer nulidade ou anulação de algum item desta política não
              prejudicará as demais, permanecendo íntegras e válidas para os
              fins.
            </Typography>
            <Typography noWrap variant="body" color="light">
              Contato
            </Typography>
            <Typography noWrap variant="body" color="light">
              Qualquer dúvida sobre a política, o usuário deverá entrar em
              contato com a Conted Tech por meio do:{' '}
              <a href="mailto:desenvolvimento@conted.tech">
                desenvolvimento@conted.tech
              </a>
            </Typography>
            <Typography noWrap variant="body" color="light">
              Qualquer um que notar desvios às diretrizes aqui expostas, pode
              relatar também nos canais citados acima, sem a necessidade de
              identificação. Estes serão encaminhados ao Encarregado para que as
              medidas necessárias sejam tomadas prontamente.
            </Typography>
            <Typography noWrap variant="body" color="light">
              A presente Política de Privacidade será regida e interpretada
              segundo a legislação brasileira, no idioma português, sendo eleito
              o Foro da Comarca de Caratinga, Minas Gerais, para dirimir
              qualquer litígio ou controvérsia envolvendo o presente documento,
              salvo ressalva específica de competência pessoal, territorial ou
              funcional pela legislação aplicável.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Policy;
