import Box from 'components/commom/Box';
import Typography from 'components/commom/Typography';
import { ReactSVG } from 'react-svg';
import Screen from './icons/screen.svg';
import Chart from './icons/charts.svg';
import Team from './icons/team.svg';
import Content from './icons/content.svg';
import Image from './img/image.png';
import Button from 'components/commom/Button';
import styledButton from 'components/StyledButton/styledButton';
import StyledButton from 'components/StyledButton/styledButton';

function SectionMec() {
  return (
    <Box bg="primary">
      <Box boxMain flex={false}>
        <Box flexDirection="column" gap={50}>
          <Box flexDirection="column" gap={12}>
            <Typography variant="title" color="primary" noWrap>
              Conheça o kit-MEC
            </Typography>
            <Typography variant="body" color="light" noWrap>
              O Kit-MEC é um serviço de consultoria especializada com
              profissionais habilitados para o ato do credenciamento e
              autorização da sua Instituição de Ensino Superior e dos seus
              cursos perante a avaliação do MEC (Ministério da Educação).
              Auxiliamos em todas as nuances das visitas dos avaliadores, desde
              a parte documental até o provimento de material didático e
              plataforma. Ele é composto por:
            </Typography>
          </Box>

          <Box flexDirection="row" flexDirectionMobile="column" gap={16}>
            <Box flexDirection="column" gap={12}>
              <ReactSVG src={Screen} />
              <Typography variant="body" color="light" fontSize="sm" noWrap>
                Implantação da Plataforma S®, customizada no formato white label
                e com as cores da sua instituição.
              </Typography>
            </Box>
            <Box flexDirection="column" gap={12}>
              <ReactSVG src={Chart} />
              <Typography variant="body" color="light" fontSize="sm" noWrap>
                Setup e treinamento para as equipes que utilizarão as soluções
                oferecidas pela Conted.tech.
              </Typography>
            </Box>
            <Box flexDirection="column" gap={12}>
              <ReactSVG src={Team} />
              <Typography variant="body" color="light" fontSize="sm" noWrap>
                Suporte à equipe multidisciplinar da instituição em visitas
                remotas do Ministério da Educação.
              </Typography>
            </Box>
            <Box flexDirection="column" gap={12}>
              <ReactSVG src={Content} />
              <Typography variant="body" color="light" fontSize="sm" noWrap>
                Conteúdo programático, ementas, referenciais bibliográficos
                básicos e complementares em todas as trilhas do saber.
              </Typography>
            </Box>
          </Box>

          <Box
            flexDirection="row"
            flexDirectionMobile="column"
            alignItems="center"
            gap={50}
            width="100%"
          >
            <img
              src={Image}
              alt="people"
              width={'100%'}
              style={{ maxWidth: '373px' }}
            />
            <Box flexDirection="column" gap={12}>
              <Typography variant="body" color="light" noWrap>
                Fornecimento de material didático conforme os instrumentos de
                avaliação do MEC produzido por especialistas em cada área.
              </Typography>
              <StyledButton
                text="Quero saber mais"
                onClick={() => {
                  window.open(
                    'https://www.youtube.com/@laboratorio_ead',
                    '_blank'
                  );
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default SectionMec;
