import Typography from 'components/commom/Typography';
import { ReactSVG } from 'react-svg';
import * as S from './styles';

export type CarouselItemProps = {
  icon: string;
  title: string;
  text: string;
};

function CarouselItem(props: CarouselItemProps) {
  const { icon, text, title } = props;
  return (
    <S.Card>
      <S.IconWrap>
        <ReactSVG src={icon} />
      </S.IconWrap>
      <Typography color="light" variant="h4" fontSize="md" noWrap>
        {title}
      </Typography>
      <Typography color="light" variant="body" fontSize="sm" noWrap>
        {text}
      </Typography>
    </S.Card>
  );
}
export default CarouselItem;
