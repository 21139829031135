import Box from 'components/commom/Box';
import Typography from 'components/commom/Typography';
import * as S from './styles';
import Ibra from './img/ibra.png';
import Ibafec from './img/ibafec.png';
import Sim from './img/sim.png';
import Unibta from './img/unibta.png';
import Etep from './img/etep.png';
import Fabin from './img/fabin.png';
import Zayn from './img/zayn.png';
import Inovation from './img/inovation.png';
import Fabras from './img/fabras.png';
import Faech from './img/faech.png';
import Fagran from './img/fagran.png';
import Fibmg from './img/fibmg.png';
import Fibms from './img/fibms.png';
import Fitec from './img/fitec.png';
import Vincit from './img/vincit.png';
import Fivar from './img/fivar.png';
import Catedral from './img/catedral.png';
import B4ead from './img/b4ead.png';
import JF from './img/JA.png';
import CBM from './img/cbm.png';
import Cefeen from './img/cefeen.png';
import Unipap from './img/UNIPAP.png';
import Inades from './img/INADES.png';
import BRASEDU from './img/BRASEDU.png';
import UNIBRAS from './img/UNIBRAS.png';
import SANARE from './img/SANARE.png';
import FAPSI from './img/FAPSI.png';
import SantaHelena from './img/SantaHelena.png';
import fasu from './img/fasu.png';
import Ello from './img/ellocursos.png';
import Uniplena from './img/Uniplena site.png';
import Cenes from './img/cenes.png';
import Iet from './img/iet.png';
import Velox from './img/velox.png';
import Turbo from './img/turbo.png';
import Seta from './img/seta.png';







function SectionPartners() {
  return (
    <Box bg="primary" justifyContent="center">
      <Box flexDirection="column" justifyContent="center" alignItems="center">
        <Box boxMain>
          <Box flexDirection="column" alignItems="center" gap={25} width="100%">
            <Typography
              variant="title"
              color="light"
              noWrap
              align="center"
              fill
            >
              Cases de Sucesso!
            </Typography>
          </Box>
        </Box>
        <S.GridContainer>
          {/* <S.Card>
            <img alt="partners logo" width={'80%'} src={Harmon} />
          </S.Card> */}
          {/* <S.Card>
            <img alt="partners logo" width={'80%'} src={Viva} />
          </S.Card> */}
          <S.Card>
            <img alt="partners logo" width={'80%'} src={Zayn} />
          </S.Card>
          <S.Card>
            <img alt="partners logo" width={'80%'} src={Fabin} />
          </S.Card>
          <S.Card>
            <img alt="partners logo" width={'80%'} src={Etep} />
          </S.Card>
          <S.Card>
            <img alt="partners logo" width={'80%'} src={Unibta} />
          </S.Card>
          <S.Card>
            <img alt="partners logo" width={'80%'} src={Sim} />
          </S.Card>
          <S.Card>
            <img alt="partners logo" width={'80%'} src={Ibafec} />
          </S.Card>
          <S.Card>
            <img alt="partners logo" width={'80%'} src={Ibra} />
          </S.Card>
          <S.Card>
            <img alt="partners logo" width={'80%'} src={Inovation} />
          </S.Card>
          <S.Card>
            <img alt="partners logo" width={'80%'} src={Fabras} />
          </S.Card>
          <S.Card>
            <img alt="partners logo" width={'80%'} src={Faech} />
          </S.Card>
          <S.Card>
            <img alt="partners logo" width={'80%'} src={Fagran} />
          </S.Card>
          <S.Card>
            <img alt="partners logo" width={'80%'} src={Fibmg} />
          </S.Card>
          <S.Card>
            <img alt="partners logo" width={'80%'} src={Fibms} />
          </S.Card>
          <S.Card>
            <img alt="partners logo" width={'80%'} src={Fitec} />
          </S.Card>
          <S.Card>
            <img alt="partners logo" width={'80%'} src={Vincit} />
          </S.Card>
          <S.Card>
            <img alt="partners logo" width={'80%'} src={Fivar} />
          </S.Card>
          <S.Card>
            <img alt="partners logo" width={'80%'} src={Catedral} />
          </S.Card>
          <S.Card>
            <img alt="partners logo" width={'80%'} src={B4ead} />
          </S.Card>

          <S.Card>
            <img alt="partners logo" width={'80%'} src={Cefeen} />
          </S.Card>
          <S.Card>
            <img alt="partners logo" width={'80%'} src={Unipap} />
          </S.Card>
          <S.Card>
            <img alt="partners logo" width={'80%'} src={Inades} />
          </S.Card>
          <S.Card>
            <img alt="partners logo" width={'80%'} src={BRASEDU} />
          </S.Card>
          <S.Card>
            <img alt="partners logo" width={'80%'} src={UNIBRAS} />
          </S.Card>
          <S.Card>
            <img alt="partners logo" width={'80%'} src={SANARE} />
          </S.Card>
          <S.Card>
            <img alt="partners logo" width={'80%'} src={SantaHelena} />
          </S.Card>
          <S.Card>
            <img alt="partners logo" width={'80%'} src={fasu} />
          </S.Card>
          <S.Card>
            <img alt="partners logo" width={'80%'} src={FAPSI} />
          </S.Card>
          <S.Card>
            <img alt="partners logo" width={'80%'} src={Ello} />
          </S.Card>
          <S.Card>
            <img alt="partners logo" width={'80%'} src={Uniplena} />
          </S.Card>
          <S.Card>
            <img alt="partners logo" width={'80%'} src={Cenes} />
          </S.Card>
          <S.Card>
            <img alt="partners logo" width={'80%'} src={Iet} />
          </S.Card>
          <S.Card>
            <img alt="partners logo" width={'80%'} src={JF} />
          </S.Card>
          <S.Card>
            <img alt="partners logo" width={'80%'} src={Velox} />
          </S.Card>
          <S.Card>
            <img alt="partners logo" width={'80%'} src={Seta} />
          </S.Card>
          <S.Card>
            <img alt="partners logo" width={'80%'} src={Turbo} />
          </S.Card>
        </S.GridContainer>
      </Box>
    </Box>
  );
}
export default SectionPartners;
