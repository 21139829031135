import styled from 'styled-components';

export const VideoWrap = styled.div`
  width: 100%;
  max-width: 880px;
  margin: auto;
  border-radius: 1rem;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.bg.dark};
`;

export const Card = styled.div`
  border-radius: 15px;
  background-color: ${({ theme }) => theme.colors.bg.secondary};
  display: flex;
  flex-direction: column;
  width: 270px;
  height: 300px;
  overflow: hidden;
`;

export const CardBody = styled.div`
  padding: 1.5rem;
`;
