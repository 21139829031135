import styled from 'styled-components';
import Image from './img/banner.png';

export const BannerWrap = styled.div`
  max-width: 100vw;
  height: 200px;
  position: relative;
  background-image: url(${Image});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #000;
  display: flex;
  justify-content: center;

  @media (min-width: 1024px) {
    height: 500px;
  }
`;
