import styled, { css } from 'styled-components';
import { variantSize } from './sizes';
import {
  TypographyAlignProps,
  TypographyColorsProps,
  TypographyVariantsProps,
  TypographyWeightProps,
  TypographyFontProps,
} from './types';

interface TypographyStyledProps {
  variant: TypographyVariantsProps;
  align: TypographyAlignProps;
  color: TypographyColorsProps;
  weight?: TypographyWeightProps;
  noWrap: boolean;
  fontSize?: 'sm' | 'md' | 'lg';
  lineHeight?: number;
  fill?: boolean;
  ellipsis?: boolean;
  font?: TypographyFontProps;
  alignDesktop?: 'start' | 'end' | 'center';
  uppercase?: boolean;
}

function variantHeading(variant: string): string {
  if (variant === 'body') return 'p';
  if (variant === 'title') return 'h2';

  return variant;
}

function fontSizePxToRem(fontSize: number): string {
  return `${fontSize / 16}rem`;
}

export const Container = styled.div`
  width: 100%;
  display: flex;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
`;

export const Heading = styled.h1.attrs<TypographyStyledProps>(
  ({ variant }) => ({
    as: variantHeading(variant),
  })
)<TypographyStyledProps>`
  font-style: normal;

  ${({ variant }) =>
    variant === 'h1' &&
    css`
      font-weight: 800;
    `}

  ${({ fill }) =>
    fill &&
    css`
      width: 100%;
    `}

  ${({ variant }) =>
    variant === ('h2' || 'h3' || 'h4') &&
    css`
      font-weight: 700;
    `}

  ${({ variant }) =>
    variant === 'h3' &&
    css`
      font-size: 1.625rem !important;

      @media (min-width: 1023px) {
        font-size: 2.25rem !important;
      }
    `}

  ${({ variant }) =>
    variant === 'body' &&
    css`
      font-weight: 400;
      font-size: 1.375rem !important;
      line-height: 1.375rem !important;
      font-family: Light;
    `}

  ${({ variant }) =>
    variant === 'title' &&
    css`
      font-weight: 800;
      font-size: 2.25rem !important;
      line-height: 2.25rem;
      font-family: Black;

      @media (min-width: 1023px) {
        font-size: 3.5rem !important;
        line-height: 3.5rem !important;
      }
    `}
    

 
  ${({ color }) =>
    color === 'primary' &&
    css`
      color: ${(props) => props.theme.colors.blue.primary};
    `};

  ${({ color }) =>
    color === 'light' &&
    css`
      color: ${(props) => props.theme.colors.light.primary};
    `};

  ${({ color }) =>
    color === 'dark' &&
    css`
      color: ${(props) => props.theme.colors.dark.primary};
    `};

  ${({ alignDesktop }) =>
    alignDesktop &&
    css`
      @media (min-width: 1024px) {
        text-align: ${alignDesktop};
      }
    `};

  text-align: ${({ align }) => align};

  ${({ weight }) =>
    weight === 'regular' &&
    css`
      font-family: Regular;
    `};

  ${({ weight }) =>
    weight === 'bold' &&
    css`
      font-family: Bold;
    `};

  ${({ weight }) =>
    weight === 'black' &&
    css`
      font-family: Black;
    `};

  display: inline-block;
  margin: 0px;
  padding: 0px;
  text-overflow: ellipsis;
  word-break: break-word;
  overflow-wrap: break-word;
  white-space: nowrap;

  ${({ noWrap }) =>
    noWrap === true &&
    css`
      overflow: unset;
      text-overflow: unset;
      white-space: unset;
    `}

  font-size: ${({ variant }) => variantSize(variant)};

  ${({ fontSize }) =>
    fontSize === 'sm' &&
    css`
      font-size: 1rem !important;
    `}

  ${({ fontSize }) =>
    fontSize === 'md' &&
    css`
      font-size: 1.625rem !important;
    `}

  ${({ fontSize }) =>
    fontSize === 'lg' &&
    css`
      font-size: 1.25rem;

      @media (min-width: 1024px) {
        font-size: 1.5rem;
      }
    `}

    ${({ font }) =>
    font &&
    css`
      font-family: ${font} !important;
    `};

  ${({ lineHeight }) =>
    lineHeight &&
    css`
      line-height: ${fontSizePxToRem(lineHeight)};
    `}

  ${({ ellipsis }) =>
    ellipsis &&
    css`
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    `}

  ${({ uppercase }) =>
    uppercase === true &&
    css`
      text-transform: uppercase;
    `}
`;
