import LayoutDefault from 'components/Layouts/LayoutDefault/component';
import SectionAbout from './Sections/AboutUs/component';
import SectionCount from './Sections/SectionCount/component';
import SectionVideo from './Sections/VideoSection/component';

function AboutUs() {
  return (
    <LayoutDefault>
      <SectionVideo />
      <SectionAbout />
      <SectionCount />
    </LayoutDefault>
  );
}
export default AboutUs;
