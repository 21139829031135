import { useState, ReactElement, useRef, useEffect } from 'react';
import * as S from './styles';
import Prev from './icons/prev.svg';
import Next from './icons/next.svg';
import { ReactSVG } from 'react-svg';

interface Items {
  items: ReactElement[];
}

function ImageSlider(props: Items) {
  const [Index, setIndex] = useState(1);
  const { items } = props;
  const [height, setHeight] = useState<number>(0);

  const ref = useRef<any>(null);

  const slideLength = items.length;

  function goRight() {
    if (Index < slideLength) {
      setIndex(Index + 1);
    } else {
      setIndex(1);
    }
  }

  function goLeft() {
    setIndex(Index - 1);
    if (Index === 1) {
      setIndex(slideLength);
    }
  }

  useEffect(() => {
    if (ref.current.clientHeight) {
      setTimeout(() => {
        setHeight(ref.current.clientHeight);
        console.log(ref.current);
      }, 1000);
    }
  }, [ref, Index]);

  return (
    <S.Container>
      <div
        style={{
          position: 'relative',
          height: `${height}px`,
        }}
      >
        {items.map((item, index) => {
          return (
            <S.ImageSliderItem
              ref={ref}
              key={index}
              active={Index === index + 1 ? true : false}
            >
              {item}
            </S.ImageSliderItem>
          );
        })}
        <S.ButtonsWrap>
          <span onClick={() => goLeft()}>
            <ReactSVG src={Prev} />
          </span>
          <span onClick={() => goRight()}>
            <ReactSVG src={Next} />
          </span>
        </S.ButtonsWrap>
      </div>
    </S.Container>
  );
}
export default ImageSlider;
