import Box from 'components/commom/Box';
import Button from 'components/commom/Button';
import Typography from 'components/commom/Typography';
import Image from './img/image.png';
import * as S from './styles';

function ContentSection() {
  return (
    <Box bg="primary">
      <Box boxMain flex={false}>
        <Box flexDirectionMobile="column" alignItems="center" gap={50}>
          <Box flexDirection="column" gap={25}>
            <Typography variant="title" color="primary" noWrap>
              Conteúdo Sob Demanda
            </Typography>
            <Typography variant="body" color="light" noWrap>
              Para transformar a educação utilizando tecnologias digitais você
              não precisa abrir mão da tradição de sua instituição. A
              Conted.tech transforma seu curso ou treinamento presencial em
              digital! Chega de perder tempo treinando e ensinando uma pessoa
              por vez. Poupe tempo e mantenha sua identidade deixando seus
              cursos gravados e prontos para uso a qualquer tempo.
            </Typography>
            <Box justifyContentMob="center">
              <Button
                onClick={() => {
                  window.location.href = '#form';
                }}
                text="Entre em contato"
              />
            </Box>
          </Box>
          <S.ImageWrap>
            <img src={Image} alt="content" />
          </S.ImageWrap>
        </Box>
      </Box>
    </Box>
  );
}
export default ContentSection;
