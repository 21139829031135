import Box from 'components/commom/Box';
import Button from 'components/commom/Button';
import Typography from 'components/commom/Typography';
import * as S from './styles';
import { useState } from 'react';
import Book, { BookComponentProps } from './components/Book';
import Book1 from './img/book1.png';
import Book2 from './img/book2.png';
import Book3 from './img/book3.png';
import Book4 from './img/book4.png';
import Book5 from './img/book5.png';

function ContedBooks() {
  const [items, setItems] = useState<BookComponentProps[]>([
    {
      id: '1',
      name: 'Antropologia Moderna',
      photo: `${Book1}`,
      author: 'Rebeca Garcia Cabral',
    },
    {
      id: '2',
      name: 'Evolução do Pensamento Administrativo I',
      photo: `${Book2}`,
      author: '',
    },
    {
      id: '3',
      name: 'Matemática e Raciocínio Lógico',
      photo: `${Book3}`,
      author: 'Vinicius Valdivia Hernandez',
    },
    {
      id: '4',
      name: 'Evolução do Pensamento Administrativo I',
      photo: `${Book4}`,
      author: 'Rebeca Garcia Cabral',
    },
    {
      id: '4',
      name: 'Evolução do Pensamento Administrativo I',
      photo: `${Book5}`,
      author: 'Rebeca Garcia Cabral',
    },
  ]);
  const [visibleItems, setVisibleItems] = useState<BookComponentProps[]>(
    items.slice(0, 3)
  );

  const loadMore = () => {
    const nextItems = items.slice(visibleItems.length, visibleItems.length + 3);
    const updatedVisibleItems = [...visibleItems, ...nextItems];
    setVisibleItems(updatedVisibleItems);
    if (updatedVisibleItems.length === items.length) {
      setItems([]);
    }
  };

  return (
    <Box bg="primary">
      <Box boxMain>
        <Box flexDirection="column" gap={50}>
          <Box
            flexDirection="row"
            flexDirectionMobile="column"
            alignItems="center"
            alignItemsMob="flex-start"
            gap={50}
          >
            <Box flexDirection="column" gap={12}>
              <Typography variant="title" fontSize="lg" color="primary" noWrap>
                Conted Editora
              </Typography>
              <Typography color="light" variant="body" noWrap>
                Nossa Editora auxilia autores na publicação de suas obras
                impressas e/ou online. Serviços como produção de capa,
                editoração, revisão ortográfica e textual, cadastro no ISBN e
                divulgação. Publicação sem complicações e burocracias e com
                preço justo!
              </Typography>
            </Box>
            <Box>
              <Button
                onClick={() => {
                  window.location.href = '#form';
                }}
                text="Publique conosco"
                variant="secondary"
              />
            </Box>
          </Box>
          <S.BooksWrap>
            <S.FlexBooks>
              {visibleItems.map((item) => (
                <Book key={item.id} {...item} />
              ))}
            </S.FlexBooks>
            {items.length > 3 && (
              <Button text="carregar mais" onClick={loadMore} />
            )}
          </S.BooksWrap>
        </Box>
      </Box>
    </Box>
  );
}
export default ContedBooks;
