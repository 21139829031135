import styled, { css } from 'styled-components';

interface ButtonStyledProps {
  bg?: 'primary' | 'secondary' | 'light' | 'dark' | 'gray' | 'blue';
  boxMain?: boolean;
  alignItems?: 'flex-start' | 'center' | 'flex-end';
  alignItemsMob?: 'flex-start' | 'center' | 'flex-end';
  justifyContent?: 'flex-start' | 'center' | 'flex-end' | 'space-between';
  justifyContentMob?: 'flex-start' | 'center' | 'flex-end' | 'space-between';
  flexDirection?: 'row' | 'column' | 'row-reverse' | 'column-reverse';
  flexDirectionMobile?: 'row' | 'column' | 'row-reverse' | 'column-reverse';
  width?: string;
  height?: string;
  gap?: number;
  container?: boolean;
  flex?: boolean;
}

export const Container = styled.div<ButtonStyledProps>`
  display: flex;
  margin: 0;
  flex-direction: ${({ flexDirection }) => flexDirection};
  align-items: ${({ alignItems }) => alignItems};
  justify-content: ${({ justifyContent }) => justifyContent};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  gap: ${({ gap }) => gap}px;

  ${({ flex }) =>
    flex === true &&
    css`
      display: flex;
    `}

  ${({ flex }) =>
    flex === false &&
    css`
      display: unset;
    `}

  ${({ boxMain }) =>
    boxMain &&
    css`
      padding: 3.125rem;
      max-width: 880px;
      margin: auto;
      width: 100%;

      @media (min-width: 1024px) {
        padding: 3.125rem 0;
      }
    `}

  ${({ container }) =>
    container === true &&
    css`
      max-width: 1024px;
      margin: auto;
    `}
    
  ${({ bg }) =>
    bg === 'secondary' &&
    css`
      background-color: ${(props) => props.theme.colors.bg.secondary};
    `}
  ${({ bg }) =>
    bg === 'light' &&
    css`
      background-color: ${(props) => props.theme.colors.bg.light};
    `}
    
  ${({ bg }) =>
    bg === 'primary' &&
    css`
      background-color: ${(props) => props.theme.colors.dark.primary};
    `}

  ${({ bg }) =>
    bg === 'gray' &&
    css`
      background-color: ${(props) => props.theme.colors.gray.primary};
    `}
  ${({ bg }) =>
    bg === 'blue' &&
    css`
      background-color: ${(props) => props.theme.colors.bg.primary};
    `}

    @media
    (max-width: 768px) {
    flex-direction: ${({ flexDirectionMobile }) => flexDirectionMobile};
    justify-content: ${({ justifyContentMob }) => justifyContentMob};
    align-items: ${({ alignItemsMob }) => alignItemsMob};
  }
`;
