import styled from 'styled-components';

export const GridWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 12px;
  }
`;

export const ImageWrap = styled.div`
  display: flex;
  & img {
    width: 100%;
  }
  @media (max-width: 769px) {
    & img {
      max-width: 330px;
      margin: auto;
    }
  }
`;

export const FlexItems = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  height: fit-content;
`;

export const Item = styled.div`
  display: flex;
  justify-content: center;
  border-radius: 1rem;
  border: solid 1px ${({ theme }) => theme.colors.blue.primary};
  color: ${({ theme }) => theme.colors.blue.primary};
  padding: 0 2rem;
  flex: none;
  height: fit-content;
  flex: 1 0 auto;
  font-family: Bold;
  font-size: 22px;
`;
