import Box from 'components/commom/Box';
import Typography from 'components/commom/Typography';
import CountUp from 'react-countup';
import * as S from './styles';

function SectionCount() {
  return (
    <Box bg="primary">
      <Box boxMain>
        <Box flexDirection="column" gap={26} alignItems="center" width="100%">
          <Box>
            <Typography color="primary" variant="title" noWrap>
              Conted.tech Em Números
            </Typography>
          </Box>
          <Box flex={false}>
            <S.CountUpWrap>
              <CountUp start={0} end={20} duration={10} delay={0} separator=".">
                {({ countUpRef }) => (
                  <S.CountUp>
                    <strong>
                      +<strong ref={countUpRef} />
                    </strong>
                    <p>Instituições Parceiras</p>
                  </S.CountUp>
                )}
              </CountUp>
              <CountUp
                start={0}
                end={500}
                duration={10}
                delay={0}
                separator="."
              >
                {({ countUpRef }) => (
                  <S.CountUp>
                    <strong>
                      +<strong ref={countUpRef} />
                    </strong>
                    <p>Disciplinas</p>
                  </S.CountUp>
                )}
              </CountUp>
              <CountUp
                start={0}
                end={1000}
                duration={10}
                delay={0}
                separator="."
              >
                {({ countUpRef }) => (
                  <S.CountUp>
                    <strong>
                      +<strong ref={countUpRef} />
                    </strong>
                    <p>Unidades de Aprendizagem</p>
                  </S.CountUp>
                )}
              </CountUp>
              <CountUp
                start={0}
                end={2000}
                duration={10}
                delay={0}
                separator="."
              >
                {({ countUpRef }) => (
                  <S.CountUp>
                    <strong>
                      +<strong ref={countUpRef} />
                    </strong>
                    <p>Vídeos Produzidos</p>
                  </S.CountUp>
                )}
              </CountUp>
              <CountUp
                start={0}
                end={100}
                duration={10}
                delay={0}
                separator="."
              >
                {({ countUpRef }) => (
                  <S.CountUp>
                    <strong>
                      +<strong ref={countUpRef} />
                    </strong>
                    <p>Livros Publicados</p>
                  </S.CountUp>
                )}
              </CountUp>
            </S.CountUpWrap>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default SectionCount;
