import styled from 'styled-components';

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  row-gap: 1.5rem;
  padding: 0 1rem;
  padding-bottom: 3.125rem;

  @media (min-width: 425px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: 1024px) {
    grid-template-columns: repeat(5, 1fr);
  }
`;

export const Card = styled.div`
  background-color: ${(theme) => theme.theme.colors.bg.secondary};
  width: 100%;
  min-width: 122px;
  max-width: 122px;
  height: 120px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: 1024px) {
    min-width: 160px;
    max-width: 160px;
  }
`;
