import styled from 'styled-components';

export const CountUpWrap = styled.div`
  background-color: ${({ theme }) => theme.colors.blue.primary};
  padding: 1.5rem;
  display: grid;
  gap: 1.5rem;
  width: fit-content;

  @media (min-width: 1024px) {
    grid-auto-flow: column;
    gap: 3rem;
    width: 100%;
  }
`;

export const CountUp = styled.div`
  & strong {
    font-size: 36px;
    font-family: Black;
  }
  & p {
    font-family: Regular;
    font-size: 1.375rem;
  }
`;
