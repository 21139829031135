import styled, { css } from 'styled-components';

interface CarouselDesktopProps {
  noCarousel?: boolean;
}

export const Container = styled.div<CarouselDesktopProps>`
  max-width: 100vw;
  display: grid;
  grid-auto-flow: column;
  gap: 35px;
  overflow: auto;
  scroll-behavior: smooth;
  padding-left: 35px;

  ${({ noCarousel }) =>
    noCarousel === true &&
    css`
      @media (min-width: 1024px) {
        max-width: 880px;
        margin: auto;
        padding-left: 0;
        justify-content: space-between;
      }
    `}

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    display: none;
  }
`;

export const Card = styled.div``;
export const ButtonWrap = styled.div`
  display: none;
  gap: 1rem;
`;
