import Box from 'components/commom/Box';
import Typography from 'components/commom/Typography';
import Carousel from 'components/utils/Carousel/component';
import CarouselItem from './CarouselItems/component';
import * as S from './styles';
import Book1 from './img/livro1.png';
import Book2 from './img/livro2.png';
import Book3 from './img/livro3.png';
import Book4 from './img/livro4.png';
import Book5 from './img/livro5.png';

function CarouselBooks() {
  return (
    <S.Container>
      <Box flexDirection="column" container gap={24}>
        <Typography
          color="light"
          variant="h3"
          weight="black"
          align="center"
          noWrap
          fill
          style={{ marginBottom: '24px' }}
        >
          Destaques
        </Typography>
        <Carousel
          noCarousel
          items={[
            <CarouselItem icon={Book1}>
              <Box flexDirection="column" gap={12} alignItems="center">
                <Box flexDirection="column">
                  <Typography
                    variant="body"
                    fontSize="sm"
                    noWrap
                    color="light"
                    lineHeight={20}
                    align="center"
                    weight="bold"
                  >
                    Antropologia Moderna
                  </Typography>
                  <Typography
                    variant="body"
                    fontSize="sm"
                    noWrap
                    color="light"
                    lineHeight={20}
                    align="center"
                    weight="regular"
                  >
                    Rebeca Garcia Cabral
                  </Typography>
                </Box>
              </Box>
            </CarouselItem>,
            <CarouselItem icon={Book2}>
              <Box flexDirection="column" gap={12} alignItems="center">
                <Box flexDirection="column">
                  <Typography
                    variant="body"
                    fontSize="sm"
                    noWrap
                    color="light"
                    lineHeight={20}
                    align="center"
                    weight="bold"
                  >
                    Evolução do Pensamento Administrativo I
                  </Typography>
                </Box>
              </Box>
            </CarouselItem>,
            <CarouselItem icon={Book3}>
              <Box flexDirection="column" gap={12} alignItems="center">
                <Box flexDirection="column">
                  <Typography
                    variant="body"
                    fontSize="sm"
                    noWrap
                    color="light"
                    lineHeight={20}
                    align="center"
                    weight="bold"
                  >
                    Matemática e Raciocínio Lógico
                  </Typography>
                  <Typography
                    variant="body"
                    fontSize="sm"
                    noWrap
                    color="light"
                    lineHeight={20}
                    align="center"
                    weight="regular"
                  >
                    Vinicius Valdivia Hernandez
                  </Typography>
                </Box>
              </Box>
            </CarouselItem>,
            <CarouselItem icon={Book4}>
              <Box flexDirection="column" gap={12} alignItems="center">
                <Box flexDirection="column">
                  <Typography
                    variant="body"
                    fontSize="sm"
                    noWrap
                    color="light"
                    lineHeight={20}
                    align="center"
                    weight="bold"
                  >
                    Evolução do Pensamento Administrativo I
                  </Typography>
                </Box>
              </Box>
            </CarouselItem>,
            <CarouselItem icon={Book5}>
              <Box flexDirection="column" gap={12} alignItems="center">
                <Box flexDirection="column">
                  <Typography
                    variant="body"
                    fontSize="sm"
                    noWrap
                    color="light"
                    lineHeight={20}
                    align="center"
                    weight="bold"
                  >
                    Matemática e Raciocínio Lógico
                  </Typography>
                  <Typography
                    variant="body"
                    fontSize="sm"
                    noWrap
                    color="light"
                    lineHeight={20}
                    align="center"
                    weight="regular"
                  >
                    Vinicius Valdivia Hernandez
                  </Typography>
                </Box>
              </Box>
            </CarouselItem>,
          ]}
        />
      </Box>
    </S.Container>
  );
}
export default CarouselBooks;
