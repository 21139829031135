import styled from 'styled-components';

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 215px;

  > img {
    width: 100%;
  }
`;

export const ImageWrap = styled.div`
  position: relative;
`;
export const ImageTextWrap = styled.div`
  position: absolute;
  left: 0;
  bottom: 12px;
`;
