import styled, { css } from 'styled-components';

interface ItemProps {
  active?: boolean;
}

export const Container = styled.div`
  position: relative;
  width: 100%;
`;

export const ImageSliderItem = styled.div<ItemProps>`
  width: 100%;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  transition: ease all 0.3s;

  ${({ active }) =>
    active === true &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`;

export const ButtonsWrap = styled.div`
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    cursor: pointer;
  }
  @media (max-width: 768px) {
    span:first-child {
      transform: translateX(-200%);
    }
    span:last-child {
      transform: translateX(200%);
    }
  }
`;
