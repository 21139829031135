import * as S from './styles';
import Platform from './img/plataforma-s.png';
import Easy from './img/easy.png';
import Simple from './img/partners.png';
import Editora from './img/editora.png';

function Banner() {
  return (
    <>
      <S.BackgroundImage>
        <S.GridCards>
          <img src={Platform} alt="companies" />
          <img src={Easy} alt="companies" />
          <img src={Simple} alt="companies" />
          <img src={Editora} alt="companies" />
        </S.GridCards>
      </S.BackgroundImage>
    </>
  );
}

export default Banner;
