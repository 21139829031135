import Box from 'components/commom/Box';
import Typography from 'components/commom/Typography';
import * as S from './styles';
import Academy from './img/academy.png';
import Partners from './img/partners.png';
import Easy from './img/easy.png';
import ReactPlayer from 'react-player';

function SimpleEcosystem() {
  return (
    <Box bg="primary">
      <Box boxMain flex={false}>
        <Box flexDirection="column" gap={50}>
          <Box flexDirection="column" gap={12}>
            <Typography color="primary" variant="title" noWrap>
              Plataforma S 
            </Typography>
            <Typography variant="body" color="light" noWrap>
              O nosso ecossistema é formado pelo conjunto de tecnologias que
              promovem uma excelente experiência entre aluno e IES.
            </Typography>
          </Box>
          <S.VideoWrap>
            <ReactPlayer
              width={'100%'}
              url={'https://vimeo.com/680191118?share=copy'}
              controls
            />
          </S.VideoWrap>
          <Box
            justifyContent="space-between"
            alignItems="center"
            flexDirectionMobile="column"
            gap={24}
          >
            <S.Card>
              <img src={Partners} alt="card" />
              <S.CardBody>
                <Typography color="light" variant="body" noWrap fontSize="sm">
                  Todo o conteúdo é revisado por copidesques, garantindo um
                  produto único, cem por cento a base de cópias
                </Typography>
              </S.CardBody>
            </S.Card>
            <S.Card>
              <img src={Academy} alt="card" />
              <S.CardBody>
                <Typography color="light" variant="body" noWrap fontSize="sm">
                  Plataforma é customizável, modular e mobile friendly.
                </Typography>
              </S.CardBody>
            </S.Card>
            <S.Card>
              <img src={Easy} alt="card" />
              <S.CardBody>
                <Typography color="light" variant="body" noWrap fontSize="sm">
                  Mais de mil unidades de aprendizagem para atender às demandas
                  imediatas da sua Instituição.
                </Typography>
              </S.CardBody>
            </S.Card>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
export default SimpleEcosystem;
