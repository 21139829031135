import Box from 'components/commom/Box';
import Typography from 'components/commom/Typography';
import Image from './img/image.png';
import * as S from './styles';

function Easy() {
  return (
    <Box bg="primary">
      <Box boxMain>
        <Box flexDirection="column">
          <Typography
            style={{ marginBottom: '12px' }}
            color="primary"
            variant="h3"
            weight="black"
            noWrap
          >
            Easy
          </Typography>
          <S.GridWrap>
            <Box flexDirection="column" gap={12}>
              <Typography color="light" noWrap variant="body">
                A{' '}
                <span style={{ color: '#13ADE0', fontWeight: '700' }}>
                  Easy
                </span>{' '}
                é nosso catálogo para cursos livres. É uma modalidade que
                comporta tanto alunos como consumidores avulsos sendo, por
                exemplo, uma oportunidade de geração de renda. Assim, o cliente
                que adquire a Easy passa a ser um revendedor dos mais de 500
                cursos já disponíveis em nossa plataforma, que traz tanto temas
                de áreas específicas, como de áreas correlatas e
                interdisciplinares.
              </Typography>
            </Box>
            <Box flex={false}>
              <img src={Image} alt="" width={'100%'} />
            </Box>
            <S.FlexItems>
              <S.Item>Exatas</S.Item>
              <S.Item>Direito</S.Item>
              <S.Item>Educação</S.Item>
              <S.Item>Administração e Negócios</S.Item>
              <S.Item>Meio Ambiente</S.Item>
              <S.Item>Tecnologia</S.Item>
              <S.Item>Saúde</S.Item>
              <S.Item>Engenharia</S.Item>
            </S.FlexItems>
          </S.GridWrap>
        </Box>
      </Box>
    </Box>
  );
}

export default Easy;
