import styled, { css } from 'styled-components';
import { ButtonVariants } from './component';

interface ButtonStyledProps {
  full?: boolean;
  disabled?: boolean;
  variant?: ButtonVariants;
  iconPosition?: 'left' | 'right';
  background?: boolean;
  hiddenTextOnMobile?: boolean;
  text?: string;
  loading?: boolean;
  neonEffect?: boolean;
}

export const Container = styled.div<ButtonStyledProps>`
  margin: 0;

  ${({ full }) =>
    full &&
    css`
      width: 100%;
    `}
`;

export const Button = styled.button<ButtonStyledProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 3rem;
  height: 46px;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.blue.primary};
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 15px;
  font-size: 0.875rem;
  line-height: 1rem;
  white-space: nowrap;
  gap: 0.5rem;
  min-width: 48px;
  border: solid 1px ${({ theme }) => theme.colors.blue.primary};
  cursor: pointer;
  ${({ neonEffect }) =>
    neonEffect &&
    css`
      &:hover {
        color: #fff;
        background-color: #29d;
        box-shadow: 0 0 10px #29d, 0 0 20px #29d, 0 0 30px #29d, 0 0 40px #29d;
        text-shadow: 0 0 5px #29d;
      }
    `}
  @media (min-width: 1024px) {
    font-size: 1.375rem;
  }

  ${({ text }) =>
    text === '' &&
    css`
      gap: 0;
    `}

  :active {
    & svg {
      transform: scale(0.85);
      transition: transform 0.125s linear;
    }
  }

  :focus {
    border: 1px solid ${({ theme }) => theme.colors.blue.primary};
  }

  ${({ loading }) =>
    loading &&
    css`
      background-color: ${({ theme }) => theme.colors.dark.primary};
      color: ${({ theme }) => theme.colors.dark.primary};
    `}

  ${({ loading }) =>
    !loading &&
    css`
      :disabled {
        background-color: #1a1a1a;
        color: #fff;
        border-color: transparent;
        cursor: not-allowed;
      }
    `}

  ${({ full }) =>
    full &&
    css`
      width: 100%;
    `}

  transition: outline 0.1s linear;

  ${({ variant }) =>
    variant === 'secondary' &&
    css`
      background-color: #fff;

      :hover {
        border-color: ${({ theme }) => theme.colors.blue.primary};
      }
    `}

  ${({ iconPosition }) =>
    iconPosition === 'right' &&
    css`
      flex-direction: row-reverse;
    `}
`;

export const Icon = styled.span<ButtonStyledProps>`
  > div > div {
    display: flex;
  }
  & svg {
    width: 18px;
  }
`;

export const Text = styled.span<{ className?: string; breakSpaces: boolean }>`
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1rem;
  white-space: nowrap;
  font-family: Bold;

  ${({ breakSpaces }) =>
    breakSpaces === true &&
    css`
      white-space: break-spaces !important;
    `}

  @media (min-width: 1024px) {
    font-size: 1.375rem;
  }
`;