import * as S from './styles';
import Typography from 'components/commom/Typography';
import { ReactSVG } from 'react-svg';
import Aspas from './icon/aspas.svg';
import Box from 'components/commom/Box';

interface ItemProps {
  description?: string;
  image?: string;
  name?: string;
  occupation?: string;
}

function SliderItem(props: ItemProps) {
  const { image, description, name, occupation } = props;
  return (
    <S.Item>
      <S.TextWrap>
        <ReactSVG src={Aspas} />
        <Typography
          noWrap
          variant="body"
          color="light"
          fontSize="sm"
          align="start"
        >
          {description}
        </Typography>
        <S.Wrap>
          <S.ImageWrap>{image && <img src={image} alt="Person" />}</S.ImageWrap>
          <Box flexDirection="column" justifyContent="center">
            <Typography
              noWrap
              fontSize="sm"
              color="light"
              variant="body"
              weight="bold"
              fill
            >
              {name}
            </Typography>
            <Typography
              noWrap
              fontSize="sm"
              color="light"
              variant="body"
              align="center"
              fill
            >
              {occupation}
            </Typography>
          </Box>
        </S.Wrap>
      </S.TextWrap>
    </S.Item>
  );
}
export default SliderItem;
