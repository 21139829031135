import LayoutDefault from 'components/Layouts/LayoutDefault/component';
import Banner from './Sections/BannerSection/component';
import Easy from './Sections/Easy/component';
import SimpleEcosystem from './Sections/Simple/component';
import SimpleAcademy from './Sections/SimpleAcademy/component';
import SimplePartners from './Sections/SimplePartners/component';

function Ecosystems() {
  return (
    <LayoutDefault>
      <Banner />
      <SimpleEcosystem />
      <SimpleAcademy />
      <Easy />
    </LayoutDefault>
  );
}
export default Ecosystems;
