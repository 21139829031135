import styled from 'styled-components';

export const GridWrap = styled.div`
  display: grid;
  gap: 1.5rem;

  @media (min-width: 1024px) {
    grid-auto-flow: column;
    gap: 0;
  }
`;

export const ListWrap = styled.ul`
  display: grid;
`;

export const ListItem = styled.li`
  display: inline-flex;
  align-items: flex-start;
  gap: 12px;
  color: #fff;
  font-family: Regular;
  font-weight: 700;
`;
