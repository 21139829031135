import React, { ReactElement } from 'react';
import * as S from './styles';
import { BoxProps } from './types';

const Box = (props: BoxProps): ReactElement => {
  const {
    children,
    boxMain,
    alignItems,
    flexDirection,
    justifyContent,
    width,
    height,
    gap,
    bg,
    container,
    flex,
    ...rest
  } = props;

  const stylesComponent = {
    boxMain,
    alignItems,
    flexDirection,
    justifyContent,
    width,
    height,
    gap,
    bg,
    container,
    flex,
  };

  return (
    <S.Container {...stylesComponent} {...rest}>
      {children}
    </S.Container>
  );
};

export default Box;
