import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
    *{
        margin:0;
        padding:0;
        box-sizing:border-box;
        font-family:Arial, Helvetica, sans-serif;
    };
    body{
        background:${(props) => props.theme.colors.bg.light};
        color:${(props) => props.theme.colors.bg.dark};
    };
    ul{
        list-style:none;
    };
`;
