export default {
  title: 'light',
  colors: {
    light: {
      primary: '#fff',
    },
    dark: {
      primary: '#000',
    },
    blue: {
      primary: '#13ADE0',
    },
    gray: {
      primary: '#E5E5E5',
    },
    bg: {
      secondary: '#1A1A1A',
      dark: '#000',
      primary: '#13ADE0',
      light: '#E5E5E5',
    },
  },
};
