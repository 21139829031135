import styled, { css } from 'styled-components';

interface NavProps {
  open: boolean;
}

export const Navbar = styled.nav`
  background: ${(props) => props.theme.colors.bg.dark};zz
`;

export const NavbarWrap = styled.div`
  padding: 0.75rem 3.125rem;
  max-width: 880px;
  margin: auto;
  width: 100%;

  @media (min-width: 1024px) {
    padding: 3.125rem 0;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  color: #fff;
  height: 72px;

  @media (min-width: 1024px) {
    height: 90px;
    padding-left: 0;
    padding-right: 0;
    max-width: 880px;
    margin: auto;
  }
`;

export const LogoWrap = styled.div<NavProps>`
  transition: ease all 0.3s;
  cursor: pointer;
  @media (min-width: 1080px) {
    > div > div > svg {
      display: block;
      width: 100px;
      height: 68px;
    }
  }
  /* ${({ open }) =>
    open === true &&
    css`
      transform: translate(-50%, 50px);
      transition: ease all 0.3s;
      z-index: 2;
      position: relative;
      right: -50%;
    `} */
`;

export const LinkContainer = styled.div<NavProps>`
  position: absolute;
  top: 100%;
  bottom: 0;
  color: #fff;
  background: ${(props) => props.theme.colors.bg.dark};
  left: 0;
  right: 0;
  display: flex;
  justify-content: flex-start;
  height: 0;
  z-index: 1;

  ${({ open }) =>
    open === true &&
    css`
      height: 130vh;
    `}

  transition: ease height 0.3s;

  @media (min-width: 1024px) {
    all: unset;
    color: ${(props) => props.theme.colors.dark.primary};
  }
`;

export const IconWrap = styled.div`
  @media (min-width: 1024px) {
    display: none;
  }
`;

export const LinksWrap = styled.ul`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 0.5rem;
  font-size: 0.875rem;
  text-align: start;
  position: relative;
  top: 3.125rem;
  left: 3.125rem;

  > li {
    cursor: pointer;
    color: ${(props) => props.theme.colors.light.primary};
    text-transform: uppercase;
    position: relative;

    :hover {
      ::before {
        content: '';
        position: absolute;
        width: 30px;
        height: 2px;
        background-color: ${(props) => props.theme.colors.blue.primary};
        bottom: 0;
      }
    }
    
  }

  @media (min-width: 1024px) {
    flex-direction: row;
    align-items: center;
    margin-top: 0;
    top: 0;
    left: 0;
    gap: 2rem;
  }
`;
