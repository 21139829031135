import styled from 'styled-components';

export const ImageWrap = styled.div`
  & img {
    max-width: 400px;

    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;
