import 'App.css';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import AboutUs from './pages/About';
import Policy from './pages/Policy';
import Ecosystems from './pages/Ecosystem';
import Materials from 'pages/Materials';
import Publisher from 'pages/Publisher';
import NotFoundPage from 'pages/NotFound';
import Blog from 'pages/Blog';

function App() {
  return (
      <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/policy" element={<Policy />} />
          <Route path="/ecosystem" element={<Ecosystems />} />
          <Route path="/materials" element={<Materials />} />
          <Route path="/publisher" element={<Publisher />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/*" element={<NotFoundPage />} />
      </Routes>
  );
}

export default App;
