import Box from 'components/commom/Box';
import Typography from 'components/commom/Typography';
import Lessons from './img/lessons.png';
import * as S from './styles';
import Designer from './img/design.png';
import Unities from './img/unities.png';
import Button from 'components/commom/Button';
import Resource from './icons/resources.svg';
import Accessibility from './icons/accessibility.svg';
import Learning from './icons/learning.svg';
import Videos from './icons/videos.svg';
import Infographics from './icons/infographics.svg';
import { ReactSVG } from 'react-svg';

function SectionMaterials() {
  return (
    <Box bg="primary">
      <Box boxMain flex={false}>
        <Box flexDirection="column" gap={50}>
          <Box flexDirection="column" gap={15}>
            <Typography variant="title" color="primary" noWrap>
              Material Didático
            </Typography>
            <Typography variant="body" noWrap color="light">
              A Conted tech conta com um diversificado catálogo com mais de 500
              disciplinas prontas e mais de 1000 unidades de aprendizagem. A
              partir do catálogo, sua IES pode oferecer cursos de graduação,
              pós-graduação, cursos técnicos, cursos de capacitação e extensão
              universitária. O catálogo conta com disciplinas prontas e também
              com Unidades de Aprendizagem (UA) separadas por áreas do
              conhecimento, caso prefira. Assim, sua Instituição terá a
              liberdade de criar disciplinas únicas, baseadas em seu PPC.
            </Typography>
            <Typography variant="title" color="primary" noWrap>
              O que é o método S ?
            </Typography>
            <Typography variant="body" noWrap color="light">

              A metodologia S visa modernizar materiais didáticos tradicionais, tornando-os conteúdos digitais interativos com enfoque em metodologias ativas. Através da trilha de aprendizagem S, seu conteúdo será enriquecido com infográficos, vídeos, podcasts, atividades interativas, e estudos de casos práticos. Rompa com o convencional e revolucione a experiência educacional do seu aluno.

            </Typography>
          </Box>

          <img src={Lessons} alt="lessons" width="100%" />

          <Box
            flexDirectionMobile="column"
            flexDirection="row"
            alignItems="flex-start"
            justifyContent="space-between"
            gap={12}
          >
            <S.Card>
              <img src={Designer} alt="" />
              <S.Body>
                <S.CardTitle>
                  <Typography
                    variant="body"
                    color="primary"
                    weight="black"
                    noWrap
                    fontSize="md"
                  >
                    Disciplinas prontas
                  </Typography>
                </S.CardTitle>
                <Box flexDirection="column" gap={12}>
                  <Typography noWrap variant="body" fontSize="sm" color="light">
                    São mais de 500 disciplinas nas áreas de saúde, educação,
                    direito, engenharia, exatas e tecnologia. Todas elas em link
                    html prontas para serem incorporadas em qualquer plataforma
                    lms e com atualização instantânea.
                  </Typography>
                  <Box justifyContentMob="center">
                    <Button
                      text="Experimente"
                      onClick={() => {
                        window.open(
                          'https://s3.us-east-2.amazonaws.com/simple.academy/603f0ad0-b0e1-4d08-a587-308a99d6a467/index.html',
                          '_blank'
                        );
                      }}
                    />
                  </Box>
                </Box>
              </S.Body>
            </S.Card>
            <S.Card>
              <img src={Unities} alt="" />
              <S.Body>
                <S.CardTitle>
                  <Typography
                    variant="body"
                    color="primary"
                    weight="black"
                    noWrap
                    fontSize="md"
                  >
                    Unidades de aprendizagem para criar suas disciplinas
                  </Typography>
                </S.CardTitle>
                <Box flexDirection="column" gap={12}>
                  <Typography noWrap variant="body" fontSize="sm" color="light">
                    As unidades de aprendizagem são como aulas, trilhas de
                    aprendizagem menores que podem ser combinadas e assim, criar
                    qualquer disciplina que conste no projeto pedagógico da
                    instituição de ensino.
                  </Typography>
                  <Box justifyContentMob="center">
                    <Button
                      text="Experimente"
                      onClick={() => {
                        window.open(
                          'https://s3.us-east-2.amazonaws.com/simple.academy/d5b15e85-b0f3-4969-a84d-3e59a240943c/index.html',
                          '_blank'
                        );
                      }}
                    />
                  </Box>
                </Box>
              </S.Body>
            </S.Card>
          </Box>

          <Box
            justifyContent="center"
            flexDirectionMobile="column"
            alignItems="center"
            gap={12}
          >
            <S.IconWrap>
              <ReactSVG src={Resource} />
              <Typography
                color="light"
                variant="body"
                weight="bold"
                align="center"
                noWrap
              >
                Recursos Interativos
              </Typography>
            </S.IconWrap>
            <S.IconWrap>
              <ReactSVG src={Learning} />
              <Typography
                color="light"
                variant="body"
                weight="bold"
                align="center"
                noWrap
              >
                Banco de Questões
              </Typography>
            </S.IconWrap>
            <S.IconWrap>
              <ReactSVG src={Videos} />
              <Typography
                color="light"
                variant="body"
                weight="bold"
                align="center"
                noWrap
                fill
              >
                Videoaulas
              </Typography>
            </S.IconWrap>
            <S.IconWrap>
              <ReactSVG src={Accessibility} />
              <Typography
                color="light"
                variant="body"
                weight="bold"
                align="center"
                noWrap
                fill
              >
                Acessibilidade
              </Typography>
            </S.IconWrap>
            <S.IconWrap>
              <ReactSVG src={Infographics} />
              <Typography
                color="light"
                variant="body"
                weight="bold"
                align="center"
                noWrap
                fill
              >
                Infográficos
              </Typography>
            </S.IconWrap>
          </Box>

          <Box justifyContent="center">
            <Button
              onClick={() => {
                window.location.href = '#form';
              }}
              text="Seja um professor conteudista"
              breakSpaces
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
export default SectionMaterials;
