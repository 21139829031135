import Box from 'components/commom/Box';
import Typography from 'components/commom/Typography';
import Lessons from './img/lessons.png';
import * as S from './styles';

function SectionMaterials() {
  return (
    <Box bg="primary">
      <Box boxMain>
        <Box flexDirection="column" gap={50}>
          <Box flexDirection="column" gap={12}>
            <Typography variant="title" color="primary" noWrap>
              Material Didático
            </Typography>
            <Typography variant="body" noWrap color="light">
              Você precisa do conteúdo certo para que a sua instituição não seja
              somente mais uma em mercado cada vez mais competitivo.
            </Typography>
          </Box>
          <img src={Lessons} alt="lessons" width="100%" />
          <Box
            flexDirectionMobile="column"
            flexDirection="row"
            alignItems="center"
            gap={12}
          >
            <S.Card>
              <Box flexDirection="column" gap={12}>
                <Typography variant="body" color="primary" weight="bold" noWrap>
                  Design Instrucional
                </Typography>
                <Box flexDirection="column" gap={12}>
                  <Typography noWrap variant="body" fontSize="sm" color="light">
                    Todas as Unidades de Aprendizagem e Disciplinas possuem uma
                    trilha de aprendizado desenvolvida por nossos profissionais
                    e que otimiza o processo de ensino-aprendizagem.
                  </Typography>
                </Box>
              </Box>
            </S.Card>
            <S.Card>
              <Box flexDirection="column" gap={12}>
                <Typography variant="body" color="primary" weight="bold" noWrap>
                  Equipe Multidisciplinar
                </Typography>
                <Box flexDirection="column" gap={12}>
                  <Typography noWrap variant="body" fontSize="sm" color="light">
                    Todos os nossos conteúdos são produzidos por uma equipe
                    multidisciplinar, contendo profissionais de diversas áreas
                    como: diagramadores, programadores, designers
                    institucionais, revisores, editores.
                  </Typography>
                </Box>
              </Box>
            </S.Card>
            <S.Card>
              <Box flexDirection="column" gap={12}>
                <Typography variant="body" color="primary" weight="bold" noWrap>
                  Mídias Transformadoras
                </Typography>
                <Box flexDirection="column" gap={12}>
                  <Typography noWrap variant="body" fontSize="sm" color="light">
                    O conteúdo é responsivo e está disponibilizado em formatos
                    digitais inovadores em vídeos, infográficos, textos e
                    podcasts.
                  </Typography>
                </Box>
              </Box>
            </S.Card>
            <S.Card>
              <Box flexDirection="column" gap={12}>
                <Typography variant="body" color="primary" weight="bold" noWrap>
                  Banco de Conteudistas
                </Typography>
                <Box flexDirection="column" gap={12}>
                  <Typography noWrap variant="body" fontSize="sm" color="light">
                    A Conted Tech atua com mais de 1.000 professores
                    conteudistas, sendo em sua grande maioria mestres e
                    doutores.
                  </Typography>
                </Box>
              </Box>
            </S.Card>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
export default SectionMaterials;
