import * as S from './styles'

function ContedBooks() {
  return (
    <>
      <S.default/>
      <S.BlogFrame frameBorder={0} src='https://blog.conted.tech/'/>
    </>
  );
}
export default ContedBooks;
