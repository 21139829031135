import * as S from './styles';

function Footer() {
  return (
    <S.Container>
      <S.Footer>
        <S.CopyRights>
          <p>
            © 2023 <strong>Conted.Tech</strong> I Todos os direitos reservados.
          </p>
        </S.CopyRights>
      </S.Footer>
    </S.Container>
  );
}
export default Footer;
