import Box from 'components/commom/Box';
import ImageSlider from 'components/utils/ImageSlider/component';
import Typography from 'components/commom/Typography';
import SliderItem from './SliderItems/component';
import Ana from './img/ana-paula.png';
import Tutora from './img/tutora.png';
import Dalma from './img/Dalma.png';
import Bruna from './img/bruna.jpeg';
import Thiago from './img/thiago.jpeg';
import * as S from './styles';

function SectionTestimonials() {
  return (
    <Box bg="primary" flex={false}>
      <S.Background>
        <Box boxMain flex flexDirection="column" gap={24}>
          <Typography color="primary" noWrap align="center" variant="title">
            Depoimentos
          </Typography>
          <Typography variant="body" color="light" noWrap>
            Veja o que nossos parceiros estão falando de nós:
          </Typography>
          <ImageSlider
            items={[
              <SliderItem
                description="Gostaria de expressar minha imensa satisfação com o Método S da Conted Tech. Além de um atendimento excepcional, o material fornecido é de alta qualidade, completo e extremamente acessível. Graças a essa abordagem interativa, conseguimos reduzir significativamente a evasão de alunos. Recomendo o Método S a todas as instituições que buscam oferecer uma educação de excelência e reter seus estudantes"
                image={Dalma}
                name="Dalma Arruda"
                occupation="Diretora Fabras"
              />,
              <SliderItem
                description="Gostaria de deixar registrada a minha gratidão pelo bom trabalho da empresa. O atendimento é sempre rápido e efetivo. O conteúdo é de excelente qualidade e a plataforma muito intuitiva. A melhor coisa que fizemos foi mudar para a plataforma S, nunca na história do ZAYN o índice de evasão foi tão baixo e atribuímos isso à qualidade do material e simplicidade da plataforma. Gratidão!"
                image={Ana}
                name="Ana Paula"
                occupation="Diretora Zayn"
              />,
              <SliderItem
                description="Desde o início do ano começamos a trabalhar com a Plataforma S aqui na Faculdade Ibra. Tem sido uma ótima experiência, tanto para nós tutores, quanto professores, administrativo, quanto pros nossos alunos. Os alunos notaram um grande diferencial na qualidade do material, e sempre que precisamos de suporte os profissionais da Conted Tech estão a disposição! Estamos muito satisfeitos com essa parceria e recomendamos à todos."
                image={Tutora}
                name="Brenda Caylani"
                occupation="Tutora Cursos de Graduação EAD Faculdade Ibra."
              />,
              <SliderItem
                description="Como diretora da Faculdade Fitec, estou extremamente satisfeita com a plataforma LMS chamada Plataforma S. Tanto nossos alunos quanto nossos funcionários têm desfrutado de uma experiência de aprendizado eficiente, intuitiva e enriquecedora. Recomendo a Plataforma S a todos que buscam excelência na educação."
                image={Bruna}
                name="Bruna Lopes"
                occupation="Diretora Fitec"
              />,
              <SliderItem
                description="Além da incrível plataforma e do material de alta qualidade, também contratamos a plataforma Easy, que tem sido uma ajuda inestimável para os estudantes que precisam cumprir horas complementares de Atividades Complementares de Graduação (ACG). Fiquei impressionado com o suporte fornecido, mesmo com o valor sendo mais acessível do que a média do mercado. Recomendo a Conted Tech a todos que buscam uma solução completa e acessível para aprimorar sua formação acadêmica."
                image={Thiago}
                name="Thiago Pegas"
                occupation="Diretor ETEP"
              />,
            ]}
          />
        </Box>
      </S.Background>
    </Box>
  );
}
export default SectionTestimonials;
