import Box from 'components/commom/Box';
import Carousel from 'components/utils/Carousel/component';
import CarouselItem from './CarouselItem/component';
import * as S from './styles';
import People from './icons/people.svg';
import Building from './icons/building.svg';
import Infos from './icons/infos.svg';
import User from './icons/arrow-user.svg';

function CarouselCards() {
  return (
    <Box flex={false} bg="primary">
      <S.Container>
        <Carousel
          noCarousel
          items={[
            <CarouselItem
              icon={People}
              title="Equipe multidisciplinar"
              text="Contamos com uma equipe diversificada de profissionais altamente qualificados, incluindo editores, diagramadores, programadores, designers instrucionais e professores encarregados da criação e desenvolvimento de todos os nossos conteúdos.





              "
            />,
            <CarouselItem
              icon={Building}
              title="Autonomia para IES"
              text="Você poderá escolher e criar suas disciplinas e cursos de acordo com suas necessidades."
            />,
            <CarouselItem
              icon={Infos}
              title="Mídias transformadoras"
              text="O conteúdo é responsivo e está disponibilizado em formatos digitais inovadores em vídeos, infográficos, textos e podcasts."
            />,
            <CarouselItem
              icon={User}
              title="Adaptável"
              text="Disponibilizados os recursos em diversos formatos e com possibilidade de integrações em qualquer meio."
            />,
          ]}
        />
      </S.Container>
    </Box>
  );
}

export default CarouselCards;
