import styled from 'styled-components';

export const IconWrap = styled.div`
  & div {
    height: 60px;
  }
`;

export const TitleWrap = styled.div`
  @media (min-width: 1024px) {
    max-width: 70%;
  }
`;

export const SocialsWrap = styled.div`
  padding: 3.125rem 0;
  display: flex;
  justify-content: center;
  gap: 3.125rem;
  flex-wrap: wrap;
`;
export const Socials = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  cursor: pointer;
  :hover {
    & p {
      color: #13ade0 !important;
      transition: ease all 0.3s;
    }
  }
`;
export const Form = styled.form`
  width: 100%;
  max-width: 430px;
  background-color: #13ade0;
  border-radius: 15px;
  padding: 3.125rem 1.563rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  & h3 {
    font-size: 2.25rem !important;
  }

  & h3 > span {
    font-family: Black;
    color: #fff;
  }
`;

export const InputsWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  & label {
    font-size: 1rem;
    font-family: Regular;
    color: #fff;
  }
`;
export const InputsWrapLast = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @media (min-width: 768px) {
    grid-area: 3/1/3/3;
  }
  & label {
    font-size: 1rem;
    font-family: Regular;
    color: #fff;
  }
`;

export const GridInputs = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  @media (min-width: 768px) {
    grid-template-columns: calc(50% - 0.5rem) calc(50% - 0.5rem);
  }
`;

export const Input = styled.input`
  all: unset;
  background-color: #fff;
  color: #000;
  font-size: 1rem;
  font-family: Bold;
  gap: 1rem;
  padding: 0.625rem;
`;
export const Select = styled.select`
  all: unset;
  background-color: #fff;
  color: #000;
  font-size: 1rem;
  font-family: Bold;
  gap: 1rem;
  padding: 0.625rem;
`;
export const TextArea = styled.textarea`
  all: unset;
  background-color: #fff;
  color: #000;
  font-size: 1rem;
  font-family: Bold;
  width: 100%;
`;
