import styled from 'styled-components';

export const Container = styled.div`
  padding: 2rem 0;
`;

export const VideoWrap = styled.div`
  width: 100%;
  max-width: 880px;
  margin: auto;
  border-radius: 1rem;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.bg.dark};
`;
