import LayoutDefault from 'components/Layouts/LayoutDefault/component';
import Policy from './Sections/Policy/component';

function PolicyIndex() {
  return (
    <LayoutDefault>
      <Policy />
    </LayoutDefault>
  );
}
export default PolicyIndex;
