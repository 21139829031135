import styled from 'styled-components';

export const Container = styled.div`
  padding-bottom: 3.125rem;

  > div {
    @media (min-width: 1024px) {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      max-width: 800px;
    }
  }

  & button {
    display: none;
  }
`;
