import Box from 'components/commom/Box';
import Button from 'components/commom/Button';

import Typography from 'components/commom/Typography';

import CarouselBooks from './CarouselBooks/component';

function SectionPublisher() {
  return (
    <Box bg="secondary" flexDirection="column" gap={50}>
      <Box boxMain>
        <Box
          flex
          flexDirectionMobile="column"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          gap={12}
        >
          <Box flexDirection="column" gap={12} style={{ maxWidth: '474px' }}>
            <Typography variant="title" color="primary" noWrap>
              Conted Editora
            </Typography>
            <Typography variant="body" color="light" weight="regular" noWrap>
              Nossa Editora auxilia autores na publicação de suas obras
              impressas e/ou online. Serviços como produção de capa, editoração,
              revisão ortográfica e textual, cadastro no ISBN e divulgação.
              Publicação sem complicações e burocracias e com preço justo!
            </Typography>
          </Box>
          <Box
            width="100%"
            flexDirection="column"
            alignItems="flex-end"
            alignItemsMob="flex-start"
            gap={30}
          >
            <Button neonEffect={true}
              onClick={() => {
                window.location.href = '#form';
              }}
              text="Publique conosco"
              variant="secondary"
            />
          </Box>
        </Box>
      </Box>
      <CarouselBooks />
    </Box>
  );
}
export default SectionPublisher;
