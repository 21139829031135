// VideoCarousel.tsx

import React, { useState, useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './VideoCarousel.css';

interface Video {
    id: number;
    title: string;
    videoUrl: string;
}

interface VideoCarouselProps {
    videos: Video[];
}

interface ArrowProps {
    className?: string;
    onClick?: React.MouseEventHandler<HTMLDivElement>;
}

const VideoCarousel: React.FC<VideoCarouselProps> = ({ videos }) => {
    const [currentSlide, setCurrentSlide] = useState<number>(0);
    const [isPlaying, setIsPlaying] = useState<boolean>(false);
    const sliderRef = useRef<Slider>(null);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        autoplay: !isPlaying, // Desativar o autoplay quando o vídeo está em reprodução
        autoplaySpeed: 3000,
        pauseOnHover: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        beforeChange: (current: number, next: number) => {
            setCurrentSlide(next);
        },
        customPaging: (i: number) => <div className={`custom-dot ${currentSlide === i ? 'active' : ''}`} />,
    };

    const handleVideoPlay = () => {
        setIsPlaying(true);
        if (sliderRef.current) {
            sliderRef.current.slickPause();
        }
    };

    const handleVideoPause = () => {
        setIsPlaying(false);
        if (sliderRef.current) {
            sliderRef.current.slickPlay();
        }
    };

    return (
        <Slider ref={sliderRef} {...settings}>
            {videos.map((video) => (
                <div key={video.id}>
                    <iframe
                        title={video.title}
                        width="100%"
                        height="315"
                        src={video.videoUrl}
                        frameBorder="0"
                        allowFullScreen
                        onMouseEnter={handleVideoPlay}
                        onMouseLeave={handleVideoPause}
                    ></iframe>
                </div>
            ))}
        </Slider>
    );
};

const NextArrow: React.FC<ArrowProps> = (props) => {
    const { className, onClick } = props;
    return <div className={`slick-arrow ${className}`} onClick={onClick} />;
};

const PrevArrow: React.FC<ArrowProps> = (props) => {
    const { className, onClick } = props;
    return <div className={`slick-arrow ${className}`} onClick={onClick} />;
};

export default VideoCarousel;
