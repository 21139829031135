import styled from 'styled-components';

export const Card = styled.div`
  background: ${(theme) => theme.theme.colors.bg.secondary};
  height: 100%;
  border-radius: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 1rem 0.5rem;
`;

export const Body = styled.div`
  span {
    color: ${(theme) => theme.theme.colors.blue.primary} !important;
    font-weight: 600;
  }
`;
