import LayoutDefault from "components/Layouts/LayoutDefault/component";

function NotFoundPage() {
  return (
    <LayoutDefault>
      <h1>Não Encontrado</h1>
    </LayoutDefault>
  );
}

export default NotFoundPage;
