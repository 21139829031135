import styled from 'styled-components';

export const Topbar = styled.div`
  padding: 0.75rem 3.125rem;
  max-width: 880px;
  margin: auto;
  width: 100%;

  @media (min-width: 1024px) {
    padding: 0.75rem 0;
  }

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LeftContent = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const RightContent = styled.div`
  display: flex;
  gap: 2rem;
`;

export const IconWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;

  & span {
    color: #fff;
    font-family: Light;
    font-size: 0.75rem;
  }

  @media (max-width: 1024px) {
    & span {
      display: none;
    }
  }

  & div {
    height: 20px;
  }

  a {
    text-decoration: none; /* Removes the underline from the link */
    color: inherit; /* Ensures the link color matches the surrounding text color */
  }
`;
