import styled, { keyframes } from 'styled-components';

const pulsingBorder = keyframes`
    0% {
        transform: scale(1);
        opacity: 0.7;
    }
    70% {
        transform: scale(1.5);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 0;
    }
`;

export const WhatsappLink = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 60px;
    height: 60px;
    text-decoration: none;

    svg {
        z-index: 1;
    }

    ::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: rgba(37, 211, 102, 0.7);
        animation: ${pulsingBorder} 1.5s infinite;
        z-index: 0;
    }
`;

