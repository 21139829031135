import styled from 'styled-components';

export const TextWrap = styled.div`
  width: 75%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Container = styled.div`
  padding: 2rem 0;
`;

export const VideoWrap = styled.div`
  width: 100%;
  max-width: 880px;
  margin: auto;
  border-radius: 1rem;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.bg.dark};
`;

export const Button = styled.button`
  padding: 10px 20px;
  border: 2px solid #13ADBC; 
  background-color: transparent;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.3s ease;
  
  &:hover {
    color: #13ADBC;
    box-shadow: 0 0 10px #13ADBC, 
                0 0 20px #13ADBC, 
                0 0 40px #13ADBC,
                0 0 80px #13ADBC;
  }
`;
