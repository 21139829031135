import styled from 'styled-components';
import Image from './img/banner.png';

export const BackgroundImage = styled.div`
  max-width: 100vw;
  height: 200px;
  position: relative;
  background-image: url(${Image});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #000;
  display: flex;
  justify-content: center;

  @media (min-width: 1024px) {
    height: 500px;
  }
`;

export const GridCards = styled.div`
  opacity: 0.95;
  width: 100%;
  max-width: 880px;
  margin: auto;
  position: absolute;
  bottom: 0;
  transform: translateY(1rem);
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  padding: 0;

  > img {
    width: 100%;
  }

  @media (max-width: 1024px) {
    padding: 0;
    padding: 0 3.125rem;
  }
`;

export const Card = styled.div`
  background-color: #000;
  width: auto;
  height: 40px;
  opacity: 0.5;
  border: solid 1px #fff;
`;
