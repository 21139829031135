import Box from 'components/commom/Box';
import ReactPlayer from 'react-player';
import * as S from './styles';

function SectionVideo() {
  return (
    <Box bg="secondary" flex={false}>
      <S.Container>
        <S.VideoWrap>
          <ReactPlayer
            width={'100%'}
            url={'https://www.youtube.com/watch?v=H15TQYTIjNM'}
            controls
          />
        </S.VideoWrap>
      </S.Container>
    </Box>
  );
}
export default SectionVideo;
