import Box from 'components/commom/Box';
import Button from 'components/commom/Button';
import Typography from 'components/commom/Typography';
import * as S from './styles';

export type BookComponentProps = {
  id?: string;
  photo: string;
  name: string;
  author?: string;
};

function Book(props: BookComponentProps) {
  const { id, photo, name, author } = props;

  return (
    <S.BookWrap>
      <Box flexDirection="column" gap={12}>
        <img src={photo} alt="book" />
        <Typography
          variant="body"
          color="light"
          weight="bold"
          align="center"
          fill
          noWrap
        >
          {name}
        </Typography>
        <Typography
          variant="body"
          color="light"
          weight="regular"
          align="center"
          fill
          noWrap
        >
          {author}
        </Typography>
      </Box>
      {/* <Button text="Comprar" onClick={() => console.log(id)} /> */}
    </S.BookWrap>
  );
}

export default Book;
