import LayoutDefault from 'components/Layouts/LayoutDefault/component';
import Banner from './Sections/BannerSection/component';
import ContedBooks from './Sections/ContedBooks/component';

function Publisher() {
  return (
    <LayoutDefault>
      <Banner />
      <ContedBooks />
    </LayoutDefault>
  );
}
export default Publisher;
