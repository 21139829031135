import Box from 'components/commom/Box';
import * as S from './styles';

function Banner() {
  return (
    <Box flex={false}>
      <S.BannerWrap />
    </Box>
  );
}
export default Banner;
