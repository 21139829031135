import styled from 'styled-components';
import { createGlobalStyle } from 'styled-components';

export const BlogFrame = styled.iframe`
  width: 100%;
  height: 100vh;
`;

export default createGlobalStyle`
 *{
  margin:0;
  padding:0;
  box-sizing:border-box;
  font-family:Arial, Helvetica, sans-serif;
  overflow: hidden;
};`