import styled from 'styled-components';

export const Container = styled.div`
  background: ${(theme) => theme.theme.colors.bg.secondary};
  height: 100%;
  border-radius: 1rem;
  width: 205px;

  > img {
    width: 130px;
  }
`;

export const Body = styled.div`
  padding: 0 1.5rem;

  span {
    color: ${(theme) => theme.theme.colors.blue.primary} !important;
    font-weight: 600;
    font-size: 22px !important;
  }

  button {
    padding: 0rem 0.75rem;
  }
`;
