import Box from 'components/commom/Box';
import Typography from 'components/commom/Typography';
import Image from './img/image.png';
import * as S from './styles';
import Check from './icons/check.svg';
import { ReactSVG } from 'react-svg';

function SimpleAcademy() {
  return (
    <Box bg="primary">
      <Box boxMain>
        <Box flexDirection="column" gap={12}>
          <S.GridWrap>
            <Box flexDirection="column" gap={12}>
              <Typography color="primary" variant="h3" weight="black" noWrap>
                Plataforma S
              </Typography>
              <Typography color="light" variant="body" noWrap>
                Buscando um Sistema de Gerenciamento LMS e um AVA amparado nos
                instrumentos avaliativos do MEC? A Plataforma S é uma plataforma
                360° que prioriza a Jornada do aluno, garantindo experiências
                educacionais cada vez mais proveitosas e marcantes.
              </Typography>
            </Box>
            <Box flex={false}>
              <img src={Image} width="100%" alt="" />
            </Box>
          </S.GridWrap>
          <Box justifyContent="flex-start" flexDirection="column" gap={12}>
            <Typography weight="bold" color="light" variant="h3">
              Caracteristicas:
            </Typography>
            <S.ListWrap>
              <S.ListItem>
                <ReactSVG src={Check} />
                Módulo de gestão acadêmica;
              </S.ListItem>
              <S.ListItem>
                <ReactSVG src={Check} />
                Módulo de gestão de polos e agentes;
              </S.ListItem>
              <S.ListItem>
                <ReactSVG src={Check} />
                AVA(Ambiente virtual de aprendizado);
              </S.ListItem>
              <S.ListItem>
                <ReactSVG src={Check} />
                Módulo de vendas;
              </S.ListItem>
              <S.ListItem>
                <ReactSVG src={Check} />
                Módulo de gestão de conteúdo;
              </S.ListItem>
              <S.ListItem>
                <ReactSVG src={Check} />
                Módulo de gestão de biblioteca;
              </S.ListItem>
              <S.ListItem>
                <ReactSVG src={Check} />
                Certificado digital;
              </S.ListItem>
              <S.ListItem>
                <ReactSVG src={Check} />
                Integração com gateway de pagamentos;
              </S.ListItem>
            </S.ListWrap>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
export default SimpleAcademy;
