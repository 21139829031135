import Box from 'components/commom/Box';
import * as S from './styles';
import Instagram from './icons/instagram.svg';
import Phone from './icons/telefone.svg';
import Email from './icons/email.svg';
import Youtube from './icons/youtube.svg';
import Linkedin from './icons/linkedin.svg';
import { ReactSVG } from 'react-svg';

function Topbar() {
  return (
    <Box bg="blue">
      <S.Topbar>
        <S.LeftContent>
          <S.IconWrap>
            <a href="https://www.instagram.com/conted.tech/" target="_blank" rel="noopener noreferrer">
              <ReactSVG src={Instagram} />
            </a>
          </S.IconWrap>
          <S.IconWrap>
            <a href="https://www.youtube.com/@laboratorio_ead" target="_blank" rel="noopener noreferrer">
              <ReactSVG src={Youtube} />
            </a>
          </S.IconWrap>
          <S.IconWrap>
            <a href="https://www.linkedin.com/company/contedtech/" target="_blank" rel="noopener noreferrer">
              <ReactSVG src={Linkedin} />
            </a>
          </S.IconWrap>
        </S.LeftContent>
        <S.RightContent>
         
          <S.IconWrap>
            <ReactSVG src={Email} />
            <a href="mailto:atendimento@contedtech.com.br">
              <span>atendimento@contedtech.com.br</span>
            </a>
          </S.IconWrap>
        </S.RightContent>
      </S.Topbar>
    </Box>
  );
}
export default Topbar;
