import styled from 'styled-components';

export const Container = styled.div`
  background: ${(theme) => theme.theme.colors.bg.secondary};
  height: 100%;
  border-radius: 1rem;
  overflow: hidden;
`;

export const Body = styled.div`
  padding: 1.5rem;

  span {
    color: ${(theme) => theme.theme.colors.blue.primary} !important;
    font-weight: 600;
  }
`;
