import LayoutDefault from 'components/Layouts/LayoutDefault/component';
import Banner from './Sections/BannerSection/component';
import CarouselCards from './Sections/CarouselCards/component';
import ContentSection from './Sections/ContentSection/component';
import SectionMaterials from './Sections/Materials/component';

function Materials() {
  return (
    <LayoutDefault>
      <Banner />
      <SectionMaterials />
      <ContentSection />
      <CarouselCards />
    </LayoutDefault>
  );
}
export default Materials;
