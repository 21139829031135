import { ReactNode } from 'react';
import * as S from './styles';

interface Box {
  children: ReactNode;
}

function Container(props: Box) {
  const { children } = props;
  return <S.Container>{children}</S.Container>;
}
export default Container;
