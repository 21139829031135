import Box from 'components/commom/Box';
import Typography from 'components/commom/Typography';
import Missao from './img/missao.png';
import Visao from './img/visao.png';
import Valores from './img/valores.png';
import * as S from './styles';

function SectionAbout() {
  return (
    <Box bg="primary">
      <Box boxMain>
        <Box flexDirection="column" gap={50} >
          <Box flexDirection="column" gap={12}>
            <Typography variant="title" color="primary" noWrap >
              Sobre nós
            </Typography>
            <Typography variant="body" color="light" noWrap >
              Conted.Tech, é uma Edtech especialista em soluções para Instituição de Ensino.
              Somos a desenvolvedora da Plataforma S. Nossos recursos tecnológicos estão solucionando
              a performance e o crescimento de muitas IES nos meios digitais, através da produção de conteúdo
              e inovações tecnológicas que auxiliam no processo de ensino-aprendizagem.
            </Typography>
          </Box>
          {/*  */}
          <Box flexDirection="column" gap={12}>
            <Box
              gap={12}
              justifyContent="center"
              flexDirectionMobile="column"
              alignItemsMob="center"
            >
              <S.Card>
                <S.ImageWrap>
                  <img src={Missao} alt="" />
                  <S.ImageTextWrap>
                    <Typography color="primary" fontSize="md">
                      Missão
                    </Typography>
                  </S.ImageTextWrap>
                </S.ImageWrap>
                <Typography variant="body" color="light" fontSize="sm" noWrap>
                  Oferecer oportunidade de soluções tecnológicas e recursos
                  educacionais para Instituições de Ensino Superior.
                </Typography>
              </S.Card>
              <S.Card>
                <S.ImageWrap>
                  <img src={Visao} alt="" />
                  <S.ImageTextWrap>
                    <Typography color="primary" fontSize="md">
                      Visão
                    </Typography>
                  </S.ImageTextWrap>
                </S.ImageWrap>
                <Typography variant="body" color="light" fontSize="sm" noWrap>
                  Ser uma Edtech referência no Brasil com material didático e
                  tecnologia de ponta para o mercado da educação nacional.
                </Typography>
              </S.Card>
              <S.Card>
                <S.ImageWrap>
                  <img src={Valores} alt="" />
                  <S.ImageTextWrap>
                    <Typography color="primary" fontSize="md">
                      Valores
                    </Typography>
                  </S.ImageTextWrap>
                </S.ImageWrap>
                <Typography variant="body" color="light" fontSize="sm" noWrap>
                  Autonomia intelectual, Senso de pesquisa, Criatividade,
                  Confiança, Harmonia e Inovação.
                </Typography>
              </S.Card>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default SectionAbout;
