import { ReactNode } from 'react';
import { ThemeProvider } from 'styled-components';
import light from 'styles/themes/light';
import Topbar from '../Topbar/component';
import Navbar from '../Navbar/component';
import Container from '../Container/component';
import PageForm from '../Form/component';
import Footer from '../Footer/component';
import GlobalStyle from 'styles/globals';

interface Box {
  children: ReactNode;
}


function LayoutDefault(props: Box) {
  const { children } = props;
  return (
    <ThemeProvider theme={light}>
      <GlobalStyle />
      <Topbar />
      <Navbar />
      <Container>
        {children}
      </Container>
      <PageForm />
      <Footer />
    </ThemeProvider>
  );
}
export default LayoutDefault;
