import Box from 'components/commom/Box';
import * as S from './styles';
import { ReactNode } from 'react';

interface CarouselItemProps {
  icon: string;
  children: ReactNode;
}

function CarouselItem(props: CarouselItemProps) {
  const { icon, children } = props;

  return (
    <S.Container>
      <Box flexDirection="column" alignItems="center" width="205px" gap={12}>
        <img src={icon} alt="carousel-item" width="120px" />
        <S.Body>{children}</S.Body>
      </Box>
    </S.Container>
  );
}
export default CarouselItem;
