import React from 'react';
import './ButtonStyles.css'; 

type StyledButtonProps = {
  text: string;
  onClick: () => void;
};

const StyledButton: React.FC<StyledButtonProps> = ({ text, onClick }) => {
  return (
    <button className="btn-14" onClick={onClick}>
      {text}
    </button>
  );
};

export default StyledButton;