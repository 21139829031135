import Box from 'components/commom/Box';
import Typography from 'components/commom/Typography';
import Carousel from 'components/utils/Carousel/component';
import CarouselItem from './CarouselItems/component';
import * as S from './styles';
import Platform from './img/platform.jpg';
import Partners from './img/partners.png';
import Easy from './img/easy.png';

function SectionCarousel() {
  return (
    <S.Container>
      <Box flexDirection="column" container gap={24}>
        <Carousel
          noCarousel
          items={[
            <CarouselItem icon={Platform}>
              <Box flexDirection="column">
                <Typography
                  variant="body"
                  fontSize="sm"
                  noWrap
                  color="light"
                  lineHeight={20}
                >
                  A <span>Plataforma S</span> é a nossa plataforma LMS com o AVA
                  integrado que garante uma experiência 360° para os alunos e
                  IES.
                </Typography>
              </Box>
            </CarouselItem>,
            <CarouselItem icon={Partners}>
              <Box flexDirection="column">
                <Typography
                  variant="body"
                  fontSize="sm"
                  noWrap
                  color="light"
                  lineHeight={20}
                >
                  O método <span>S</span>  foi desenvolvido por designers Instrucionais
                  e tem por finalidade promover uma melhor experiência de ensino-aprendizagem aos alunos,
                  experiência essa baseada em metodologias ativas.
                </Typography>
              </Box>
            </CarouselItem>,
            <CarouselItem icon={Easy}>
              <Box flexDirection="column">
                <Typography
                  variant="body"
                  fontSize="sm"
                  noWrap
                  color="light"
                  lineHeight={20}
                >
                  A plataforma <span>Easy</span> é um streaming de educação com
                  mais de 500 cursos, sendo mais de 1000 vídeos. Tudo isso
                  alinhado com as normativas do MEC e pronta para você!
                </Typography>
              </Box>
            </CarouselItem>,
          ]}
        />
      </Box>
    </S.Container>
  );
}
export default SectionCarousel;
